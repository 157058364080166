import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import right_arrow from '../images/right_arrow.svg'
import templates from '../static/templates.json'
import loading from '../images/loading.svg'
import { Box, Typography, Button } from '@mui/material'
import qs from 'qs'

const Signup = ({ setData, setActiveStep }) => {
  const [authLoading, setAuthLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const savedDesign = JSON.parse(sessionStorage.getItem('savedDesign'))

  const getDesigns = async (orderID) => {
    const token = localStorage.getItem('token')

    await axios
      .get(process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com' + '/api/v1/designs', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => {
        const data = res.data.find((e) => e.Id === Number(orderID))
        const newData = [
          {
            settings: data.DesignData[0].settings,
            logotype: data.DesignLogotype || null,
            amount: Number(data.Amount.replaceAll('"', '')),
            data: data.DesignData[0].data,
            groups: data.DesignData[0].groups,
            isEditing: true,
            isSharedDesign: false,
            isEditFromCart: true,
            Id: data.Id
          }
        ]

        /* REDIRECT TO EDIT PAGE */

        window.sessionStorage.setItem('data', JSON.stringify(newData))
        const templateData = templates.find((e) => e.id === data.DesignId)
        const savedActiveStep = JSON.parse(sessionStorage.getItem('savedActiveStep'))
        window.sessionStorage.setItem('template', JSON.stringify(templateData))
        if (savedActiveStep === 0) {
          return navigate('/admin')
        }
        window.sessionStorage.setItem('step', JSON.stringify(savedActiveStep))
        setActiveStep(savedActiveStep)
        setData(newData)
        navigate('/create')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const saveDesign = async () => {
    const orderData = {
      DesignName: savedDesign.DesignName,
      DesignId: savedDesign.DesignId,
      DesignData: [
        {
          data: savedDesign.data.map((item) => {
            return item
          }),
          groups: savedDesign.groups,
          settings: savedDesign.settings
        }
      ],
      DesignDescription: savedDesign.description,
      DesignLogotype: savedDesign.logotype,
      Amount: savedDesign.amount,
      Status: 1,
      Id: savedDesign.Id !== undefined ? savedDesign.Id : null
    }

    const formData = new FormData()

    if (!savedDesign.logotype.includes('http')) {
      formData.append('file', savedDesign.logotype)
    }

    formData.append('data', JSON.stringify(orderData))

    const token = localStorage.getItem('token')

    const designConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com' + '/api/v1/addDesign',
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    await axios
      .request(designConfig)
      .then((response) => {
        getDesigns(response.data.designId)
      })
      .catch((error) => {
        console.log(error.response.data.message)
        setErrorMessage(error.response.data.message)
      })
  }

  const registerUser = () => {
    setAuthLoading(true)
    const data = qs.stringify({
      Email: email,
      Password: password
    })

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com' + '/api/v1/register',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data
    }

    axios
      .request(config)
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem('token', response.data.token)

          if (savedDesign) {
            saveDesign()
          } else {
            navigate('/admin')
          }
        }
        setAuthLoading(false)
      })
      .catch((error) => {
        console.log(error.response.data.message)
        setErrorMessage(error.response.data.message)
        setAuthLoading(false)
      })
  }

  const createDesignAsGuest = () => {
    setActiveStep(0)
    navigate('/create')
  }

  const isDisabled = email.length < 6 || password.length < 6 || authLoading

  return (
    <Box className="loginPage">
      <h1>Register</h1>
      <p className="loginSubtitle">Login or register to save and share designs</p>
      <Box className="loginInput">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="username@email.com"
          value={email}
          required
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
        />
      </Box>
      <Box className="loginInput">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          name="password"
          id="password"
          placeholder="password"
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
        />
      </Box>
      {errorMessage.length > 0 ? <p className="errorMessage">{errorMessage}</p> : null}
      <Button
        variant="contained"
        disabled={isDisabled}
        sx={{ width: '100%', height: '50px', flexDirection: 'row', alignItems: 'center', borderRadius: '12px', backgroundColor: '#7A92BA' }}
        onClick={(e) => {
          if (email.length < 6 || password.length < 6) {
            alert('Please fill in all fields')
          } else if (!/\S+@\S+\.\S+/.test(email)) {
            alert('Please enter a valid email address')
          } else {
            registerUser()
          }
        }}
      >
        <Typography sx={{ fontFamily: 'Abel', fontSize: '20px', marginRight: '6px' }}>Sign Up</Typography>
        <img src={right_arrow} alt="Right Arrow" />
        {authLoading && (
          <img style={{ width: '20px', marginLeft: '10px', position: 'relative', left: '10px' }} src={loading} alt="Loading" />
        )}
      </Button>
      <p className="loginBottomText">
        Already have an account? <Link to={'/login'}>Log in</Link>
      </p>
      <Box sx={{ cursor: 'pointer', marginTop: '30px' }} onClick={createDesignAsGuest}>
        <Typography sx={{ fontFamily: 'Abel', color: '#000000', textAlign: 'center', fontSize: '13px', textDecorationLine: 'underline' }}>
          Create Design as Guest
        </Typography>
      </Box>
    </Box>
  )
}

export default Signup
