import React, { useEffect, useState } from 'react'

const LabelNumbering = ({ id, globalData }) => {
  const template = JSON.parse(window.sessionStorage.getItem('template'))

  const [isHaveNumeration, setIsHaveNumeration] = useState(false)
  const [listNumber, setListNumber] = useState(1)
  useEffect(() => {
    if (template.rows === 1) {
      setIsHaveNumeration('LabelNumberBottom')
      setListNumber(id)
      if (template.id === 'black_lion_pbr_xlr') {
        if (id > 1 && id < template.labelsPerRow) {
          setIsHaveNumeration('LabelNumberBottom')
          setListNumber(id - 1)
        }
      }
    } else if (template.rows === 2) {
      if (id <= template.labelsPerRow) {
        setIsHaveNumeration('LabelNumberTop')
        setListNumber(id)
      } else {
        setIsHaveNumeration('LabelNumberBottom')
        setListNumber(id)
      }
      if (template.id === 'behringer_px3000') {
        if (id >= template.labelsPerRow / 2) {
          setIsHaveNumeration('LabelNumberTop')
          setListNumber(id - 1)
        }
        if (id + 1 >= template.labelsPerRow) {
          setIsHaveNumeration('LabelNumberBottom')
          setListNumber(id - 1)
        }
        if (id + 2 > template.labelsPerRow * 2 - template.labelsPerRow / 2) {
          setIsHaveNumeration('LabelNumberBottom')
          setListNumber(id - 2)
        }
      }
      if (template.id === 'black_lion_pbr_trs_trs3' || template.id === 'black_lion_pbr_trs_bt') {
        if (id > 1 && id < template.labelsPerRow) {
          setIsHaveNumeration('LabelNumberTop')
          setListNumber(id - 1)
        }
        if (id + 1 >= template.labelsPerRow + 1) {
          setIsHaveNumeration('LabelNumberBottom')
          setListNumber(id - 3)
        }
      }
    }
  }, [globalData])

  return <span className={`labelNumber unselectable ${isHaveNumeration}`}>{listNumber}</span>
}

export default LabelNumbering
