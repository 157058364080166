import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import templates from '../static/templates.json'

const AddToCart = ({ setActiveStep }) => {
  const navigate = useNavigate()
  const designId = window.location.pathname.split('/')[2]

  // const token = localStorage.getItem('token');

  const orderData = getDesignData()

  orderData
    .then((res) => {
      const template = templates.find((template) => template.id === res.data.DesignId)

      const orderData = {
        DesignName: template.name,
        DesignId: template.id,
        DesignData: res.data.DesignData,
        DesignDescription: template.DesignDescription,
        Amount: res.data.Amount,
        Status: 1,
        Id: res.data.Id !== undefined ? res.data.Id : null
      }

      const data = new FormData()
      if (res.data.DesignLogotype !== null) {
        data.append('file', res.data.logotype)
      }
      data.append('data', JSON.stringify(orderData))

      const token = localStorage.getItem('token')

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com' + '/api/v1/addOrder',
        data,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

      axios
        .request(config)
        .then((response) => {
          if (response.data.orderId) {
            navigate('/cart/')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    })
    .catch((err) => {
      console.log(err)
    })

  async function getDesignData () {
    const design = await axios.get(process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com' + '/api/v1/getDesign/' + designId, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
    return design
  }

  return <div>Loading...</div>
}

export default AddToCart
