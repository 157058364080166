import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import templates from '../static/templates.json'

const EditFromCart = ({ setActiveStep }) => {
  const navigate = useNavigate()

  const orderID = window.location.pathname.split('/')[2]

  async function getOrders () {
    const order = await axios.get(
      process.env.REACT_APP_API_URL ||
          'https://labelmaker.traceaudioserver.com' + `/api/v1/orders/${orderID}`
    )
    return order
  }

  const orderData = getOrders()

  orderData
    .then((res) => {
      const data = res.data
      const newData = [
        {
          settings: data.DesignData[0].settings,
          logotype:
            data.DesignLogotype === null
              ? ''
              : process.env.REACT_APP_API_URL ||
                'https://labelmaker.traceaudioserver.com' + `${data.DesignLogotype}`,
          amount: Number(data.Amount.replaceAll('"', '')),
          data: data.DesignData[0].data,
          groups: data.DesignData[0].groups,
          isEditing: false,
          isSharedDesign: false,
          isEditFromCart: true,
          Id: data.Id
        }
      ]

      window.sessionStorage.setItem('data', JSON.stringify(newData))
      const templateData = templates.find((e) => e.id === data.DesignId)
      window.sessionStorage.setItem('template', JSON.stringify(templateData))
      window.sessionStorage.setItem('step', 1)
      setActiveStep(1)
      navigate('/create')
    })
    .catch((err) => {
      console.log(err)
    })
}

export default EditFromCart
