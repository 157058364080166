import React, { useEffect, useState } from 'react'
import './LogoStep.css'
import Labels from '../../../Components/Labels/Labels'
import upload_image from '../../../images/upload_image.svg'
import LogoSelect from '../../../Popups/LogoSelect/LogoSelect'
import { useNavigate } from 'react-router-dom'
import bookmark from '../../../images/bookmark.svg'
import loading from '../../../images/loading.svg'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import axios from 'axios'

function LogoStep (props) {
  const [logoUploadOpen, setLogoUploadOpen] = useState(false)
  const [logotypeImage, setLogotypeImage] = useState(null)
  const [saveButton, setSaveButton] = useState('Save to My Designs')
  const [saveDesignLoading, setSaveDesignLoading] = useState(false)

  const template = JSON.parse(window.sessionStorage.getItem('template'))

  const navigation = useNavigate()

  useEffect(() => {
    window.sessionStorage.removeItem('savedActiveStep')
  }, [])

  const isSmallScreen = useMediaQuery('(max-width:530px)')

  const sendData = (apiURL) => {
    const orderData = {
      DesignName: template.name,
      DesignId: template.id,
      DesignLogotype: props.data[0].logotype || null,
      DesignData: [
        {
          data: props.data[0].data.map((item) => {
            return item
          }),
          groups: props.data[0].groups,
          settings: props.data[0].settings
        }
      ],
      DesignDescription: template.DesignDescription,
      Amount: props.data[0].amount,
      Status: 1,
      Id: props.data[0].Id !== undefined ? props.data[0].Id : null
    }

    const data = new FormData()
    if (!props.data[0].logotype.includes('http')) {
      data.append('file', props.data[0].logotype)
    }
    data.append('data', JSON.stringify(orderData))

    const token = localStorage.getItem('token')

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com' + apiURL,
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    setSaveDesignLoading(true)
    axios
      .request(config)
      .then((response) => {
        if (response.data === 'Design updated successfully') {
          setSaveButton('Your design is saved!')
          setSaveDesignLoading(false)
        } else if (response.data.designId) {
          const newData = [{ ...props.data[0], isEditing: true, Id: response.data.designId }]
          props.setData(newData)
          setSaveButton('Your design is saved!')
          setSaveDesignLoading(false)
        }
      })
      .catch((error) => {
        setSaveDesignLoading(false)
        console.log(error)
      })
  }

  return (
    <Box className="labelsStep">
      <Box className="stepTexts">
        <h1 className="main-title">Add Your Logo (+$5.00)</h1>
        <p className="optionalText">(optional)</p>
        <p>
          {
            'Make your label your own by adding a logo to the left rack ear of the label. This step is optional. If you add a logo, it will replace our TA logo. Don’t worry, we won’t be too hurt.'
          }
        </p>
      </Box>
      <Box className="contentBlock">
        <Box className="contentButtons">
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              sx={{ borderRadius: '10px', height: '45px', backgroundColor: '#7A92BA' }}
              onClick={() => setLogoUploadOpen(true)}
            >
              <Typography sx={{ fontFamily: 'Abel', marginRight: '10px' }}>{`${!isSmallScreen ? 'Upload' : ''} Logo`}</Typography>
              <img src={upload_image} alt="Down" />
            </Button>
            <Box>
              {window.localStorage.getItem('token') !== null
                ? (
                <Button
                  variant="contained"
                  sx={{ borderRadius: '10px', height: '45px', backgroundColor: '#7A92BA' }}
                  disabled={saveDesignLoading}
                  onClick={(e) => {
                    sendData(
                      props.data[0].isEditing === true
                        ? props.data[0].isSharedDesign === true
                          ? '/api/v1/addDesign'
                          : '/api/v1/editDesign'
                        : '/api/v1/addDesign'
                    )
                  }}
                >
                  {saveDesignLoading === true ? <img style={{ marginRight: '6px', width: '20px' }} src={loading} alt="Loading" /> : null}
                  {!isSmallScreen && (
                    <Typography sx={{ fontFamily: 'Abel', marginRight: '10px' }}>
                      {props.data[0].isEditing === true ? (props.data[0].isSharedDesign === true ? saveButton : 'Update Data') : saveButton}
                    </Typography>
                  )}
                  <img src={bookmark} alt="Down" />
                </Button>
                  )
                : (
                <Button
                  variant="contained"
                  sx={{ borderRadius: '10px', height: '45px', backgroundColor: '#7A92BA' }}
                  disabled={saveDesignLoading}
                  onClick={(e) => {
                    sessionStorage.setItem(
                      'savedDesign',
                      JSON.stringify({
                        ...props.data[0],
                        DesignId: template.id,
                        DesignName: template.name
                      })
                    )
                    sessionStorage.setItem('savedActiveStep', props.activeStep)
                    sessionStorage.setItem('savedTempate', template.id)
                    navigation('/login?redirect=save-design/' + template.id)
                  }}
                >
                  {!isSmallScreen && <Typography sx={{ fontFamily: 'Abel', marginRight: '10px' }}>Save to My Designs</Typography>}
                  <img src={bookmark} alt="bookmark" />
                </Button>
                  )}
            </Box>
          </Box>
        </Box>
        <Labels step="logo" data={props.data} logotype={logotypeImage} setLogoUploadOpen={setLogoUploadOpen} />
        {logoUploadOpen && (
          <LogoSelect
            setLogoUploadOpen={setLogoUploadOpen}
            setLogotypeImage={setLogotypeImage}
            data={props.data}
            setData={props.setData}
            activeStep={props.activeStep}
          />
        )}
      </Box>
    </Box>
  )
}

export default LogoStep
