import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://labelmaker.traceaudioserver.com/api'
})

instance.interceptors.request.use(
  (config) => config,
  (error) => error
)

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      console.error('Error response from server:', error.response.status)
    } else if (error.request) {
      console.error('Error request:', error.request)
    } else {
      console.error('Error:', error.message)
    }

    return Promise.reject(error)
  })

export default instance
