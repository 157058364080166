import React, { useEffect, useState } from 'react'
import './Label.css'
import double from '../../../images/double.svg'
import LabelNumbering from '../../LabelNumbering/LabelNumbering'
import fontColorContrast from 'font-color-contrast'

const Label = ({
  index,
  dataList,
  middleLine,
  data,
  globalData,
  selectedLabel,
  setSelectedLabel,
  fontClass,
  updateTopLabel,
  updateBottomLabel,
  updateMiddleLabel,
  setData
}) => {
  const template = JSON.parse(window.sessionStorage.getItem('template'))
  const [isLabelInGroup, setIsLabelInGroup] = useState(false)
  const [firstID, setFirstID] = useState(null)
  const [groupData, setGroupData] = useState([
    {
      groupRowCount: 0,
      groupRowItems: 0,
      secondRowStart: null,
      countOfCharacters: 10
    }
  ])
  const [isBigLabel, setIsBigLabel] = useState(false)
  const [isFakeLabel, setIsFakeLabel] = useState(false)
  const [fontColor, setFontColor] = useState('')
  const [topBigLabel, setTopBigLabel] = useState('')
  const [bottomBigLabel, setBottomBigLabel] = useState('')

  const [labelStyle, setLabelStyle] = useState({
    backgroundColor: '',
    borderTopLeftRadius: '',
    borderTopRightRadius: '',
    borderBottomLeftRadius: '',
    borderBottomRightRadius: ''
  })

  const isSecondRowItem = data.id > globalData[0].data.length / 2
  const isMiddleLine = middleLine === true
  const isMiddleLineWithLessBigger = data.id < template?.labelsPerRow + 1
  const isMiddleLabelNotEdit = data.middleLabel !== 'Edit'
  const centerOfDataList = Math.ceil(globalData[0].data.length / 2 + 1)
  const isSamsonTemplate = 'samson_s_patch_plus'
  const isBehringerTemplate = 'behringer_px3000'
  const isDBXTemplate = 'dbx_pb_48'
  const isNeutrikTemplate = 'neutrik_nys_spp_l1'
  const isArtTemplate = 'art_p48'
  const isArtXlrTemplate = 'art_p16_xlr'
  const isBlackLionPbrXlrTemplate = 'black_lion_pbr_xlr'
  const isBlackLionPbrTrsTrs3 = 'black_lion_pbr_trs_trs3'
  const isBlackLionPbrTrsBt = 'black_lion_pbr_trs_bt'

  const getCircleSize = () =>
    ({
      [isSamsonTemplate]: 'samsonCircle',
      [isBehringerTemplate]: 'behringerCircle',
      [isDBXTemplate]: 'dbxCircle',
      [isNeutrikTemplate]: 'neutrikCircle',
      [isArtTemplate]: 'artCircle'
    }[template.id])

  const getBigLabelPosition = () => {
    if (template.rows === 1) {
      return template.firstRowBigLabelPosition
    } else {
      if (isSecondRowItem) {
        return template.secondRowBigLabelPosition
      } else {
        return template.firstRowBigLabelPosition
      }
    }
  }

  const getBottomBigLabelStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonBottomBigLabel',
      [isBehringerTemplate]: 'behringerBottomBigLabel',
      [isDBXTemplate]: 'dbxBottomBigLabel',
      [isNeutrikTemplate]: 'neutrikBottomBigLabel',
      [isArtTemplate]: 'artBottomBigLabel',
      [isArtXlrTemplate]: 'artXlrBottomBigLabel',
      [isBlackLionPbrTrsTrs3]: 'blackLionPbrTrsTrs3BottomBigLabel',
      [isBlackLionPbrTrsBt]: 'blackLionPbrTrsBtBottomBigLabel'
    }[template.id])

  const getTopBigLabelStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonTopBigLabel',
      [isBehringerTemplate]: 'behringerTopBigLabel',
      [isDBXTemplate]: 'dbxTopBigLabel',
      [isNeutrikTemplate]: 'neutrikTopBigLabel',
      [isArtTemplate]: 'artTopBigLabel',
      [isArtXlrTemplate]: 'artXlrTopBigLabel'
    }[template.id])

  const getTopLabelFirstRowStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonTopLabelFirstRow',
      [isBehringerTemplate]: 'behringerTopLabelFirstRow',
      [isDBXTemplate]: 'dbxTopLabelFirstRow',
      [isNeutrikTemplate]: 'neutrikTopLabelFirstRow',
      [isArtTemplate]: 'artTopLabelFirstRow',
      [isArtXlrTemplate]: 'artXlrTopLabelFirstRow',
      [isBlackLionPbrXlrTemplate]: 'blackLionPbrXlrTopLabelFirstRow',
      [isBlackLionPbrTrsTrs3]: 'blackLionPbrTrsTrs3TopLabelFirstRow',
      [isBlackLionPbrTrsBt]: 'blackLionPbrTrsBtTopLabelFirstRow'
    }[template.id])

  const getBottomLabelFirstRowStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonBottomLabelFirstRow',
      [isBehringerTemplate]: 'behringerBottomLabelFirstRow',
      [isDBXTemplate]: 'dbxBottomLabelFirstRow',
      [isNeutrikTemplate]: 'neutrikBottomLabelFirstRow',
      [isArtTemplate]: 'artBottomLabelFirstRow',
      [isArtXlrTemplate]: 'artXlrBottomLabelFirstRow',
      [isBlackLionPbrXlrTemplate]: 'blackLionPbrXlrBottomLabelFirstRow',
      [isBlackLionPbrTrsTrs3]: 'blackLionPbrTrsTrs3BottomLabelFirstRow',
      [isBlackLionPbrTrsBt]: 'blackLionPbrTrsBtBottomLabelFirstRow'
    }[template.id])

  const getTopLabelSecondRowStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonTopLabelSecondRow',
      [isBehringerTemplate]: 'behringerTopLabelSecondRow',
      [isDBXTemplate]: 'dbxTopLabelSecondRow',
      [isNeutrikTemplate]: 'neutrikTopLabelSecondRow',
      [isArtTemplate]: 'artTopLabelSecondRow',
      [isBlackLionPbrXlrTemplate]: 'blackLionPbrXlrTopLabelFirstRow',
      [isBlackLionPbrTrsTrs3]: 'blackLionPbrTrsTrs3TopLabelSecondRow',
      [isBlackLionPbrTrsBt]: 'blackLionPbrTrsBtTopLabelSecondRow'
    }[template.id])

  const getBottomLabelSecondRowStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonBottomLabelSecondRow',
      [isBehringerTemplate]: 'behringerBottomLabelSecondRow',
      [isDBXTemplate]: 'dbxBottomLabelSecondRow',
      [isNeutrikTemplate]: 'neutrikBottomLabelSecondRow',
      [isArtTemplate]: 'artBottomLabelSecondRow',
      [isBlackLionPbrXlrTemplate]: 'blackLionPbrXlrBottomLabelFirstRow',
      [isBlackLionPbrTrsTrs3]: 'blackLionPbrTrsTrs3BottomLabelSecondRow',
      [isBlackLionPbrTrsBt]: 'blackLionPbrTrsBtBottomLabelSecondRow'
    }[template.id])

  const getBigLabelWidth = () => {
    if (template.id === isBlackLionPbrXlrTemplate) {
      if (groupData.containFakeLabels) {
        return groupData.groupRowItems * 167 - 108 + 'px'
      }
      if (groupData.containedLeftFakeLabels) {
        return groupData.groupRowItems * 167 + 58 + 'px'
      }
      if (groupData.containedRightFakeLabels) {
        return groupData.groupRowItems * 167 - 167 + 'px'
      }
      return groupData.groupRowItems * 167 + 'px'
    }
    if (template.id === isBlackLionPbrTrsBt || template.id === isBlackLionPbrTrsTrs3) {
      if (groupData.containedLeftFakeLabels) {
        return groupData.groupRowItems * 118 + 58 + 'px'
      }
      if (groupData.containedRightFakeLabels) {
        return groupData.groupRowItems * 118 - 118 + 'px'
      }
      if (groupData.containFakeLabels) {
        return groupData.groupRowItems * 118 - 30 + 'px'
      }
      return groupData.groupRowItems * 118 + 'px'
    }
    if (groupData.containFakeLabels) {
      return groupData.groupRowItems * 110 - 80 + 'px'
    }
    if (isBigLabel) {
      return groupData.groupRowItems * 110 + 'px'
    }
    if (!isBigLabel) {
      return groupData.groupRowItems * 110 + 'px'
    }
  }

  const getLabelNumber = (id) => {
    if (template.id === isBehringerTemplate) {
      if (data.id > 1 && data.id < template.labelsPerRow) {
        return id - 1
      }
      if (data.id > template.labelsPerRow + 1) {
        return id - 3
      }
    }
    return id
  }

  const labelNumber = getLabelNumber(data.id)
  const bigLabelWidth = getBigLabelWidth()

  const updateTopBigLabel = (text) => {
    if (text.length > groupData.countOfCharacters) {
      return
    }
    const currentGroup = globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)

    const newData = [...globalData]
    setTopBigLabel(text)
    const index = globalData[0].groups.findIndex((e) => e.id === currentGroup.id)
    newData[0].groups[index].topText = text
    setData(newData)
  }

  const updateBottomBigLabel = (text) => {
    if (text.length > groupData.countOfCharacters) {
      return
    }
    const currentGroup = globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)

    const newData = [...globalData]
    setBottomBigLabel(text)
    const index = globalData[0].groups.findIndex((e) => e.id === currentGroup.id)
    newData[0].groups[index].bottomText = text
    setData(newData)
  }

  useEffect(() => {
    const currentGroup = globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)
    if (currentGroup) {
      setTopBigLabel(currentGroup.topText)
      setBottomBigLabel(currentGroup.bottomText)
    }
  }, [])

  const handleInputClick = (e) => {
    e.target.select()
  }

  const checkIdInLabels = (data, cell) => {
    for (let i = 0; i < data.length; i++) {
      const labels = data[i].labels
      if (labels.includes(cell.id)) {
        const color = fontColorContrast(data[i].color || '#ffffff')
        setFontColor(color)
        return setIsLabelInGroup(true)
      }
    }
    const color = fontColorContrast(cell.color || '#ffffff')
    setFontColor(color)
    return setIsLabelInGroup(false)
  }

  useEffect(() => {
    checkIdInLabels(globalData[0].groups, data)
  }, [globalData])

  useEffect(() => {
    if (globalData[0]?.groups?.length > 0) {
      const currentGroup = globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)

      const labels = currentGroup?.labels

      if (labels?.length > 0) {
        const newData = {
          groupRowCount: 0,
          groupRowItems: 0,
          secondRowStart: null,
          countOfCharacters: 10
        }

        if (template.id === 'behringer_px3000') {
          if ((labels.includes(13) && labels.includes(15)) || (labels.includes(40) && labels.includes(42))) {
            newData.containFakeLabels = true
          }
        }
        if (template.id === isBlackLionPbrXlrTemplate) {
          if (labels.includes(1) && labels.includes(18)) {
            newData.containFakeLabels = true
          }
          if (labels.includes(1)) {
            newData.containedLeftFakeLabels = true
          }
          if (labels.includes(18)) {
            newData.containedRightFakeLabels = true
          }
        }
        if (template.id === isBlackLionPbrTrsTrs3 || template.id === isBlackLionPbrTrsBt) {
          if (labels.includes(1) || labels.includes(27)) {
            newData.containedLeftFakeLabels = true
          }
          if (labels.includes(26) || labels.includes(52)) {
            newData.containedRightFakeLabels = true
          }
          if (labels.includes(1) || labels.includes(27)) {
            newData.containFakeLabels = true
          }
          if (labels.includes(26) || labels.includes(52)) {
            newData.containFakeLabels = true
          }
        }

        if (labels.every((num) => num <= template.labelsPerRow)) {
          newData.groupRowCount = 1
        }
        if (labels.every((num) => num > template.labelsPerRow)) {
          newData.groupRowCount = 1
        }
        if (labels.some((num) => num <= template.labelsPerRow) && labels.some((num) => num > template.labelsPerRow)) {
          newData.groupRowCount = 2
        }

        newData.groupRowItems = labels.length / newData.groupRowCount

        newData.secondRowStart = labels.some((num) => num > template.labelsPerRow) ? labels[newData.groupRowItems] : null

        newData.countOfCharacters = newData.groupRowItems * 10

        if (currentGroup !== undefined) {
          setFirstID(labels[0])
        }

        setGroupData(newData)
      }
    }
  }, [globalData])

  useEffect(() => {
    const currentID = data.id

    const groupData = [...globalData[0].groups].find((e) => e.labels.indexOf(currentID) !== -1)

    const rowLength = template.labelsPerRow

    const topNeighbor = globalData[0].data.find((cell) => cell.id === currentID - rowLength)
    const rightNeighbor = globalData[0].data.find((cell) => cell.id === currentID + 1)
    const bottomNeighbor = globalData[0].data.find((cell) => cell.id === currentID + rowLength)
    const leftNeighbor = globalData[0].data.find((cell) => cell.id === currentID - 1)

    const hasColoredTopNeighbor = !!topNeighbor?.color && topNeighbor?.color !== '#ffffff'
    const hasColoredRightNeighbor = !!rightNeighbor?.color && rightNeighbor?.color !== '#ffffff'
    const hasColoredBottomNeighbor = !!bottomNeighbor?.color && bottomNeighbor?.color !== '#ffffff'
    const hasColoredLeftNeighbor = !!leftNeighbor?.color && leftNeighbor?.color !== '#ffffff'

    if (template.firstLarge === true) {
      const cornerLabels = [1, template.labelsPerRow, template.labelsPerRow + 1, template.labelsPerRow * template.rows]

      if (cornerLabels.includes(data.id)) {
        setIsBigLabel(true)
      }
    }

    let fakeLabelsArray = []

    if (template.fakeLabels === true) {
      const fakeLabelID = (template.labelsPerRow / 2).toFixed(0)

      if (template.rows > 1) {
        fakeLabelsArray = [Number(fakeLabelID), Number(fakeLabelID) + Number(template.labelsPerRow)]
      }
    }

    if (fakeLabelsArray.includes(data.id)) {
      setIsFakeLabel(true)
    }

    const styles = {
      backgroundColor: '',
      borderTopLeftRadius: '',
      borderTopRightRadius: '',
      borderBottomLeftRadius: '',
      borderBottomRightRadius: '',
      borderColor: ''
    }

    if (template.rows === 2) {
      styles.borderTopLeftRadius = '0px'
      styles.borderTopRightRadius = '0px'
      styles.borderBottomLeftRadius = '0px'
      styles.borderBottomRightRadius = '0px'

      if (data.id < template.labelsPerRow + 1) {
        styles.borderTopLeftRadius = '8px'
        styles.borderTopRightRadius = '8px'

        // 1 row
      } else {
        styles.borderBottomLeftRadius = '8px'
        styles.borderBottomRightRadius = '8px'
        // 2 row
      }
    } else {
      styles.borderBottomLeftRadius = '8px'
      styles.borderBottomRightRadius = '8px'
      styles.borderTopLeftRadius = '8px'
      styles.borderTopRightRadius = '8px'
    }

    if (globalData[0].data.length > 0) {
      globalData[0].data.forEach((label, index) => {
        if (label.id === data.id) {
          styles.backgroundColor = label.color + ' !important'
        } else {
          styles.backgroundColor = '#F0EEF0'
        }
      })

      styles.backgroundColor = globalData[0].data[data.id - 1].color
    }

    /* GETTING LABEL COLOR */

    if (globalData[0].groups.length > 0) {
      globalData[0].groups.forEach((group, index) => {
        if (group.labels.indexOf(data.id) !== -1) {
          styles.backgroundColor = group.color
          // styles.borderColor = group.color;
        }
      })
    }

    if (!groupData) {
      hasColoredTopNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID - rowLength))
        ? (styles.borderTopColor = '#000000')
        : (styles.borderTopStyle = 'hidden')
      hasColoredRightNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID + 1))
        ? (styles.borderRightColor = '#000000')
        : (styles.borderRightStyle = 'hidden')
      template.rows === 2 && currentID === template.labelsPerRow
        ? (styles.borderRightColor = '#FFFFFF')
        : (styles.borderRightColor = '#000000')
      hasColoredBottomNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID + rowLength))
        ? (styles.borderBottomColor = '#000000')
        : (styles.borderBottomStyle = 'hidden')
      hasColoredLeftNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID - 1))
        ? (styles.borderLeftColor = '#000000')
        : (styles.borderLeftStyle = 'hidden')
    }

    /* BORDER-RADIUS DETERMINE */

    if (groupData !== undefined) {
      function updateStyles (
        topLeft,
        topRight,
        bottomLeft,
        bottomRight,
        topColor = '#000000',
        rightColor = '#000000',
        bottomColor = '#000000',
        leftColor = '#000000',
        topStyle = 'hidden',
        rightStyle = 'hidden',
        bottomStyle = 'hidden',
        leftStyle = 'hidden'
      ) {
        styles.borderTopLeftRadius = `${topLeft}px`
        styles.borderTopRightRadius = `${topRight}px`
        styles.borderBottomLeftRadius = `${bottomLeft}px`
        styles.borderBottomRightRadius = `${bottomRight}px`
        styles.borderTopColor = `${topColor}`
        styles.borderRightColor = `${rightColor}`
        styles.borderBottomColor = `${bottomColor}`
        styles.borderLeftColor = `${leftColor}`
        styles.borderTopStyle = `${topStyle}`
        styles.borderRightStyle = `${rightStyle}`
        styles.borderBottomStyle = `${bottomStyle}`
        styles.borderLeftStyle = `${leftStyle}`
      }

      const hasTopNeighbor = groupData.labels.includes(currentID - rowLength)
      const hasBottomNeighbor = groupData.labels.includes(currentID + rowLength)
      const hasLeftNeighbor = groupData.labels.includes(currentID - 1)
      const hasRightNeighbor = groupData.labels.includes(currentID + 1)
      if (template.rows === 2) {
        if (!hasTopNeighbor && !hasLeftNeighbor) {
          updateStyles(8, 0, 0, 0, '#000000', groupData.color, groupData.color, '#000000', 'solid', 'hidden', 'hidden', 'solid')
        }
        if (!hasTopNeighbor && !hasRightNeighbor) {
          updateStyles(0, 8, 0, 0, '#000000', '#000000', groupData.color, groupData.color, 'solid', 'solid', 'hidden', 'hidden')
        }
        if (!hasBottomNeighbor && !hasLeftNeighbor) {
          updateStyles(0, 0, 8, 0, groupData.color, groupData.color, '#000000', '#000000', 'hidden', 'hidden', 'solid', 'solid')
        }
        if (!hasBottomNeighbor && !hasRightNeighbor) {
          updateStyles(0, 0, 0, 8, groupData.color, '#000000', '#000000', groupData.color, 'hidden', 'solid', 'solid', 'hidden')
        }
        if (hasLeftNeighbor && hasRightNeighbor) {
          updateStyles(0, 0, 0, 0, '#000000', groupData.color, '#000000', groupData.color, 'solid', 'hidden', 'solid', 'hidden')
        }
        if (!hasTopNeighbor && hasLeftNeighbor && !hasRightNeighbor) {
          updateStyles(0, 8, 0, 0, '#000000', '#000000', groupData.color, groupData.color, 'solid', 'solid', 'hidden', 'hidden')
        }
        if (hasTopNeighbor && !hasLeftNeighbor && !hasRightNeighbor) {
          updateStyles(0, 0, 8, 8, groupData.color, '#000000', '#000000', '#000000', 'hidden', 'solid', 'solid', 'solid')
        }
        if (hasBottomNeighbor && !hasLeftNeighbor && !hasRightNeighbor) {
          updateStyles(8, 8, 0, 0, '#000000', '#000000', groupData.color, '#000000', 'solid', 'solid', 'hidden', 'solid')
        }
        if (!hasTopNeighbor && hasLeftNeighbor && hasRightNeighbor && hasBottomNeighbor) {
          updateStyles(0, 0, 0, 0, '#000000', groupData.color, groupData.color, groupData.color, 'solid', 'hidden', 'hidden', 'hidden')
        }
        if (hasTopNeighbor && hasLeftNeighbor && hasRightNeighbor && !hasBottomNeighbor) {
          updateStyles(0, 0, 0, 0, groupData.color, groupData.color, '#000000', groupData.color, 'hidden', 'hidden', 'solid', 'hidden')
        }
        if (!hasLeftNeighbor && !hasTopNeighbor && !hasBottomNeighbor && hasRightNeighbor) {
          updateStyles(8, 0, 0, 0, '#000000', groupData.color, '#000000', '#000000', 'solid', 'hidden', 'solid', 'solid')
        }
        if (!hasRightNeighbor && !hasTopNeighbor && !hasBottomNeighbor && hasLeftNeighbor) {
          updateStyles(0, 8, 0, 0, '#000000', '#000000', '#000000', groupData.color, 'solid', 'solid', 'solid', 'hidden')
        }
        if (!hasLeftNeighbor && !hasTopNeighbor && !hasBottomNeighbor && hasRightNeighbor && currentID > rowLength) {
          updateStyles(0, 0, 8, 0, '#000000', groupData.color, '#000000', '#000000', 'solid', 'hidden', 'solid', 'solid')
        }
        if (hasLeftNeighbor && !hasTopNeighbor && !hasBottomNeighbor && !hasRightNeighbor && currentID > rowLength) {
          updateStyles(0, 0, 0, 8, '#000000', '#000000', '#000000', groupData.color, 'solid', 'solid', 'solid', 'hidden')
        }
      } else {
        if (!hasRightNeighbor && hasLeftNeighbor) {
          updateStyles(0, 8, 0, 8, '#000000', '#000000', '#000000', groupData.color, 'solid', 'solid', 'solid', 'hidden')
        }
        if (hasRightNeighbor && hasLeftNeighbor) {
          updateStyles(0, 0, 0, 0, '#000000', groupData.color, '#000000', groupData.color, 'solid', 'hidden', 'solid', 'hidden')
        }
        if (hasRightNeighbor && !hasLeftNeighbor) {
          updateStyles(8, 0, 8, 0, '#000000', groupData.color, '#000000', '#000000', 'solid', 'hidden', 'solid', 'solid')
        }
      }
    }

    setLabelStyle(styles)
  }, [globalData])

  return (
    <>
      {template.double === true
        ? (
        <div
          id={`cell-label-${index}`}
          style={labelStyle}
          className={`labelItem doubleLabel
          ${!isLabelInGroup && data.color !== 'white' && data.color !== '#ffffff' && 'labelItemBorder'}
          ${isFakeLabel === true ? 'fakeLabel' : ''}
          ${isBigLabel === true ? 'bigLabel' : ''}
          ${selectedLabel === data.id && 'activeLabelFake'}
          ${isMiddleLineWithLessBigger && isMiddleLine ? 'middleLine' : ''}`}
          onClick={(e) => {
            setSelectedLabel(data.id)
          }}
        >
          <div
            className={`${selectedLabel === data.id && 'activeLabel'} activeLabelBackground `}
            style={{
              borderBottomLeftRadius: labelStyle.borderBottomLeftRadius,
              borderBottomRightRadius: labelStyle.borderBottomRightRadius,
              borderTopLeftRadius: labelStyle.borderTopLeftRadius,
              borderTopRightRadius: labelStyle.borderTopRightRadius
            }}
          />
          {(getBigLabelPosition() === 1 && !!isLabelInGroup && firstID === data.id) ||
          (isLabelInGroup === true && groupData.secondRowStart === data.id)
            ? (
            <input
              onClick={handleInputClick}
              className={`labelHorCenter ${fontColor === '#000000' ? 'cellLabelInput-black' : 'cellLabelInput-white'} ${
                selectedLabel === data.id && 'activeLabelP'
              } ${getTopBigLabelStyle()} ${fontClass}`}
              style={{
                width: isBigLabel === true ? groupData.groupRowItems * 175 - 135 + 215 + 'px' : groupData.groupRowItems * 175 + 'px',
                color: fontColor,
                fontWeight: 600,
                pointerEvents: 'auto',
                outline: 'none',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '16px'
              }}
              value={topBigLabel}
              onChange={(e) => updateTopBigLabel(e.target.value)}
              placeholder="Edit Merged Label"
            />
              )
            : null}
          <div
            className={`${isSecondRowItem && template.id !== isArtXlrTemplate ? getTopLabelSecondRowStyle() : getTopLabelFirstRowStyle()}`}
          >
            <input
              onClick={handleInputClick}
              style={{
                pointerEvents: isLabelInGroup && getBigLabelPosition() === 1 ? 'none' : 'auto',
                opacity: isLabelInGroup && getBigLabelPosition() === 1 ? 0 : 1,
                fontWeight: 600,
                color: fontColor,
                marginLeft: data.id === 1 ? '34px' : 'auto',
                marginRight: data.id === template.labelsPerRow ? '34px' : 'auto'
              }}
              className={`cellLabelInput ${fontColor === '#000000' ? 'cellLabelInput-black' : 'cellLabelInput-white'} ${fontClass}`}
              disabled={isLabelInGroup && getBigLabelPosition() === 1}
              type="text"
              placeholder="Edit"
              value={data.topLabel || ''}
              onChange={(e) => updateTopLabel(e.target.value)}
            />
          </div>
          <img
            src={double}
            style={index === 0 ? { left: '45px' } : {}}
            className={`doubleLabelImage ${data.id % 2 ? '' : 'transparent'}`}
            alt="Double"
          />
          {template.firstLarge === true
            ? (
            <p
              style={{ color: fontColor }}
              className={`middleLabel ${selectedLabel === data.id && 'activeLabelP'} ${fontClass} ${
                isMiddleLabelNotEdit && 'changedLabel'
              }`}
            >
              {data.middleLabel}
            </p>
              )
            : null}
          {(getBigLabelPosition() === 2 && !!isLabelInGroup === true && firstID === data.id) ||
          firstID === data.id ||
          (isLabelInGroup === true && groupData.secondRowStart === data.id)
            ? (
            <input
              onClick={handleInputClick}
              className={`labelHorCenterBottom ${fontColor === '#000000' ? 'cellLabelInput-black' : 'cellLabelInput-white'} ${
                selectedLabel === data.id && 'activeLabelP'
              } ${getBottomBigLabelStyle()} ${fontClass}`}
              style={{
                width: isBigLabel === true ? groupData.groupRowItems * 175 - 135 + 215 + 'px' : groupData.groupRowItems * 175 + 'px',
                color: fontColor,
                pointerEvents: 'auto',
                outline: 'none',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '16px'
              }}
              value={bottomBigLabel}
              onChange={(e) => updateBottomBigLabel(e.target.value)}
              placeholder="Edit Merged Label"
            />
              )
            : null}
          <div
            className={`${
              isSecondRowItem && template.id !== isArtXlrTemplate ? getBottomLabelSecondRowStyle() : getBottomLabelFirstRowStyle()
            }`}
          >
            <input
              onClick={handleInputClick}
              style={{
                color: fontColor,
                pointerEvents: !!isLabelInGroup && getBigLabelPosition() === 2 ? 'none' : 'auto',
                opacity: isLabelInGroup ? 0 : 1,
                fontWeight: 600,
                marginLeft: data.id === 1 ? '34px' : 'auto',
                marginRight: data.id === template.labelsPerRow ? '34px' : 'auto'
              }}
              className={`cellLabelInput ${fontColor === '#000000' ? 'cellLabelInput-black' : 'cellLabelInput-white'}`}
              disabled={!!isLabelInGroup && getBigLabelPosition() === 2}
              type="text"
              placeholder="Edit"
              value={data.bottomLabel}
              onChange={(e) => updateBottomLabel(e.target.value)}
            />
          </div>
          <LabelNumbering id={data.id} globalData={globalData} />
        </div>
          )
        : (
        <div
          id={`cell-label-${index}`}
          style={labelStyle}
          className={`labelItem ${template.id === isBlackLionPbrXlrTemplate && 'blackLionPbrXlrCellWr'} ${
            template.id === isBlackLionPbrTrsTrs3 && 'blackLionPbrTrsTrs3CellWr'
          } ${template.id === isBlackLionPbrTrsBt && 'blackLionPbrTrsBtCellWr'} ${
            ((data.id === globalData[0].data.length && template.id === isBlackLionPbrXlrTemplate) ||
              (data.id === 1 && template.id === isBlackLionPbrXlrTemplate)) &&
            'blackLionPbrXlrSmallCellWr'
          } ${
            ((data.id === globalData[0].data.length && template.id === isBlackLionPbrTrsTrs3) ||
              (data.id === 1 && template.id === isBlackLionPbrTrsTrs3) ||
              (data.id === centerOfDataList && template.id === isBlackLionPbrTrsTrs3) ||
              (data.id === centerOfDataList - 1 && template.id === isBlackLionPbrTrsTrs3)) &&
            'blackLionPbrTrsTrs3SmallCellWr'
          } ${
            ((data.id === globalData[0].data.length && template.id === isBlackLionPbrTrsBt) ||
              (data.id === 1 && template.id === isBlackLionPbrTrsBt) ||
              (data.id === centerOfDataList && template.id === isBlackLionPbrTrsBt) ||
              (data.id === centerOfDataList - 1 && template.id === isBlackLionPbrTrsBt)) &&
            'blackLionPbrTrsTrs3SmallCellWr'
          } ${!isLabelInGroup && data.color !== 'white' && data.color !== '#ffffff' && 'labelItemBorder'}
          ${selectedLabel === data.id && 'activeLabelFake'}
          ${template.id === isArtTemplate && (data.id === 1 || data.id === centerOfDataList) && 'artWiderItemLeft'} ${
            template.id === isArtTemplate &&
            (data.id === centerOfDataList - 1 || data.id === globalData[0].data.length) &&
            'artWiderItemRight'
          } ${isFakeLabel === true ? 'fakeLabel' : ''} ${isBigLabel && 'onlyOneLabel'}`}
          onClick={(e) => {
            setSelectedLabel(data.id)
          }}
        >
          <div className={`${!!middleLine && data.id < template.labelsPerRow + 1 ? 'middleLine' : ''} pointerNone`} />
          <div className={`${template.id === isBlackLionPbrTrsBt && data.id === centerOfDataList - 2 && 'blackLionPbrTrsBtRectangle'} pointerNone`} />
          <div
            className={`${selectedLabel === data.id && 'activeLabel'} activeLabelBackground `}
            style={{
              borderBottomLeftRadius: labelStyle.borderBottomLeftRadius,
              borderBottomRightRadius: labelStyle.borderBottomRightRadius,
              borderTopLeftRadius: labelStyle.borderTopLeftRadius,
              borderTopRightRadius: labelStyle.borderTopRightRadius
            }}
          />
          <div
            className={`${
              template.id === isBehringerTemplate && (data.id === 1 || data.id === centerOfDataList) && 'largeTemplateLabelWr'
            }`}
          >
            {(getBigLabelPosition() === 1 && !!isLabelInGroup && firstID === data.id) ||
            (getBigLabelPosition() === 1 && !!isLabelInGroup && groupData.secondRowStart === data.id)
              ? (
              <input
                onClick={handleInputClick}
                className={`labelHorCenter ${fontColor === '#000000' ? 'cellLabelInput-black' : 'cellLabelInput-white'} ${
                  selectedLabel === data.id && 'activeLabelP'
                } ${getTopBigLabelStyle()} ${fontClass}`}
                style={{
                  width: bigLabelWidth,
                  color: fontColor,
                  pointerEvents: 'auto',
                  outline: 'none',
                  backgroundColor: 'transparent',
                  border: 'none',
                  fontSize: '16px'
                }}
                value={topBigLabel}
                onChange={(e) => updateTopBigLabel(e.target.value)}
                placeholder="Edit Merged Label"
              />
                )
              : null}
            <div
              className={`${template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && 'hideLabel'} ${
                isBigLabel && 'hideLabel'
              } ${isSecondRowItem ? getTopLabelSecondRowStyle() : getTopLabelFirstRowStyle()}`}
            >
              <input
                onClick={handleInputClick}
                style={{
                  color: fontColor,
                  pointerEvents: isLabelInGroup && getBigLabelPosition() === 1 ? 'none' : 'auto',
                  opacity: isLabelInGroup && getBigLabelPosition() === 1 ? 0 : 1
                }}
                className={`cellLabelInput ${fontColor === '#000000' ? 'cellLabelInput-black' : 'cellLabelInput-white'} ${fontClass}`}
                disabled={isLabelInGroup && getBigLabelPosition() === 1}
                type="text"
                placeholder="Edit"
                value={data.topLabel}
                onChange={(e) => updateTopLabel(e.target.value)}
              />
            </div>
          </div>
          <div
            className={`labelCircle ${
              template.id === isBlackLionPbrTrsBt && data.id === centerOfDataList - 2 && 'blackLionPbrTrsBtRectangleFirstRow'
            } ${
              template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && 'blackLionPbrTrsBtRectangleSecondRow'
            } ${selectedLabel === data.id && 'activeLabelCircle'} ${template.id === isBlackLionPbrXlrTemplate && 'blackLionPbrXlrCircle'} ${
              template.id === isBehringerTemplate &&
              (data.id === centerOfDataList - 1 || data.id === globalData[0].data.length) &&
              'mirroredLargeItemCircle'
            } ${getCircleSize()} `}
          ></div>
          <div
            className={`bottomLabelWr ${
              template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && 'blackLionPbrTrsBtBottomLabelWrMargin'
            } ${template.id === isBehringerTemplate && (data.id === 1 || data.id === centerOfDataList) && 'largeTemplateLabelWr'}`}
          >
            {(getBigLabelPosition() === 2 && !!isLabelInGroup && firstID === data.id) ||
            (getBigLabelPosition() === 2 && !!isLabelInGroup && groupData.secondRowStart === data.id)
              ? (
              <input
                onClick={handleInputClick}
                className={`labelHorCenterBottom ${fontColor === '#000000' ? 'cellLabelInput-black' : 'cellLabelInput-white'} ${
                  selectedLabel === data.id && 'activeLabelP'
                } ${getBottomBigLabelStyle()} ${fontClass}`}
                style={{
                  width: bigLabelWidth,
                  color: fontColor,
                  pointerEvents: 'auto',
                  outline: 'none',
                  backgroundColor: 'transparent',
                  border: 'none',
                  fontSize: '16px'
                }}
                value={bottomBigLabel}
                onChange={(e) => updateBottomBigLabel(e.target.value)}
                placeholder="Edit Merged Label"
              />
                )
              : null}
            <div
              className={`${template.id === isBlackLionPbrTrsBt && data.id === centerOfDataList - 2 && 'hideLabel'} ${
                template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && 'blackLionPbrTrsBtPreLastInput'
              } ${isBigLabel && 'hideLabel'} ${isSecondRowItem ? getBottomLabelSecondRowStyle() : getBottomLabelFirstRowStyle()}`}
            >
              <input
                onClick={handleInputClick}
                style={{
                  color: fontColor,
                  pointerEvents: !!isLabelInGroup && getBigLabelPosition() === 2 ? 'none' : 'auto',
                  opacity: !!isLabelInGroup && getBigLabelPosition() === 2 ? 0 : 1
                }}
                className={`cellLabelInput ${fontColor === '#000000' ? 'cellLabelInput-black' : 'cellLabelInput-white'} ${fontClass}`}
                disabled={!!isLabelInGroup && getBigLabelPosition() === 2}
                type="text"
                placeholder="Edit"
                value={data.bottomLabel}
                onChange={(e) => updateBottomLabel(e.target.value)}
              />
            </div>
          </div>
          {isBigLabel && (
            <div className={`onlyOneLabelInput ${template.id === isBlackLionPbrXlrTemplate && 'blackLionPbrXlrOnlyOneLabel'}`}>
              <input
                onClick={handleInputClick}
                style={{ color: fontColor }}
                className={`cellLabelInput ${
                  fontColor === '#000000' ? 'cellLabelInput-black' : 'cellLabelInput-white'
                } ${fontClass} oneLabelInput`}
                type="text"
                id="oneLabelInput"
                placeholder="Edit"
                value={data.middleLabel || ''}
                onChange={(e) => updateMiddleLabel(e.target.value)}
              />
            </div>
          )}
          {(template.id === isBehringerTemplate ||
            template.id === isBlackLionPbrTrsTrs3 ||
            template.id === isBlackLionPbrTrsBt ||
            template.id === isBlackLionPbrXlrTemplate) &&
          (data.id === 1 ||
            data.id === template.labelsPerRow ||
            data.id === template.labelsPerRow + 1 ||
            data.id === template.labelsPerRow * template.rows)
            ? null
            : (
            <LabelNumbering id={labelNumber} globalData={globalData} />
              )}
        </div>
          )}
    </>
  )
}

export default Label
