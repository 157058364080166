import React, { useState } from 'react'
import './FontSelect.css'
import close from '../../images/close.svg'
import { Box, Button } from '@mui/material'

function FontSelect (props) {
  const [fonts, setFonts] = useState([
    {
      name: 'Open Sans',
      class: 'openSans',
      active: true
    },
    {
      name: 'Roboto Mono',
      class: 'robotoMono',
      active: false
    },
    {
      name: 'Roboto Slab',
      class: 'robotoSlab',
      active: false
    },
    {
      name: 'PT Serif',
      class: 'ptSerif',
      active: false
    },
    {
      name: 'Caveat',
      class: 'caveat',
      active: false
    }
  ])

  function selectFont (index) {
    const newFonts = [...fonts]
    newFonts.forEach((font) => {
      font.active = false
    })

    if (index < 0) {
      index = fonts.length - 1
    } else if (index > fonts.length - 1) {
      index = 0
    }

    newFonts[index].active = true
    setFonts(newFonts)
  }

  function saveSelectedFont () {
    const data = [...props.data]
    data[0].settings[0].fontName = fonts.find((e) => e.active === true).name
    data[0].settings[0].fontClass = fonts.find((e) => e.active === true).class
    props.setData(data)
    props.setFontPopupOpen(false)
  }

  // function updateSelectedLabelFont() {
  //   if (props.selectedLabel !== null) {
  //     let data = [...props.data];
  //     data[0].data.forEach((label) => {
  //       if (label.id === props.selectedLabel) {
  //         label.font = fonts.find((e) => e.active === true).class;
  //       }
  //     });
  //     props.setData(data);
  //     props.setFontPopupOpen(false);
  //   }
  // }

  return (
    <Box className="popup" sx={{
      zIndex: 1000
    }} >
      <Box className="popupBlock">
        <Box className="popupClose">
          <p>Global Typography</p>
          <img src={close} alt="Close" onClick={(e) => props.setFontPopupOpen(false)} />
        </Box>

        <Box className="popupContent">
          <Box className="typoSelect">
            {fonts.map((font, index) => (
              <p
                className={`${font.class} ${font.active ? 'selectedLanguage' : ''}`}
                key={index}
                onClick={(e) => selectFont(index)}
              >
                {font.name}
              </p>
            ))}
          </Box>
        </Box>
        <Box className="popupBottomButton">
          <Button
            variant="outlined"
            sx={{
              color: '#FFFFFF',
              borderColor: '#FFFFFF',
              borderRadius: '14px',
              borderStyle: 'solid',
              borderWidth: '1px',
              '&:hover': {
                color: '#FFFFFF'
              }
            }}
            className="selectButton"
            onClick={(e) => {
              saveSelectedFont()
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default FontSelect
