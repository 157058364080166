import React, { useEffect, useState } from 'react'
import './ColorSelect.css'
import close from '../../images/close.svg'
import { CompactPicker, SketchPicker } from 'react-color'
import { Button, Box, Typography, useMediaQuery } from '@mui/material'

const ColorSelect = ({
  data,
  selectedLabelsArray,
  selectedGroup,
  setData,
  clearSelectedLabels,
  setColorPopupOpen
}) => {
  const BASIC = 'basic'
  const ADVANCED = 'advanced'
  const [color, setColor] = useState('#FFFFFF')
  const [mode, setMode] = useState(BASIC)
  const recentColorsLocal = sessionStorage.getItem('recentColors')
  const [recentColors, setRecentColors] = useState([
    { color: null },
    { color: null },
    { color: null },
    { color: null },
    { color: null },
    { color: null },
    { color: null },
    { color: null },
    { color: null },
    { color: null }
  ])

  const colorsArray = [
    '#FFFFFF',
    '#ECECEC',
    '#D6D6D6',
    '#C2C2C2',
    '#ADADAD',
    '#999999',
    '#858586',
    '#707070',
    '#5C5C5C',
    '#474747',
    '#333333',
    '#000000',
    '#133648',
    '#071D53',
    '#0F0638',
    '#2A093B',
    '#370C39',
    '#541107',
    '#532009',
    '#53340D',
    '#523E0F',
    '#65611B',
    '#505218',
    '#2B3D16',
    '#1E4C63',
    '#0F2E76',
    '#180B4E',
    '#3F1244',
    '#4E1629',
    '#781F0E',
    '#722F10',
    '#734C16',
    '#73591A',
    '#8C8629',
    '#707625',
    '#3F5623',
    '#2E6C8C',
    '#1841A3',
    '#280C72',
    '#591E77',
    '#6F223D',
    '#A62C17',
    '#A0451A',
    '#A06B23',
    '#9F7D28',
    '#C3BC3C',
    '#9DA436',
    '#587934',
    '#3C8AB0',
    '#2155CE',
    '#331C8E',
    '#702898',
    '#8D2E4F',
    '#D03A20',
    '#CA5A24',
    '#C8862E',
    '#C99F35',
    '#F3EC4E',
    '#C6D047',
    '#729B44',
    '#479FD3',
    '#2660F5',
    '#4725AB',
    '#8C33B5',
    '#AA395D',
    '#EB512E',
    '#ED732E',
    '#F3AE3D',
    '#F5C944',
    '#FEFB67',
    '#DDEB5C',
    '#86B953',
    '#59C4F7',
    '#4E85F6',
    '#5733E2',
    '#AF43EB',
    '#D44A7A',
    '#ED6C59',
    '#EF8C56',
    '#F3B757',
    '#F6CD5B',
    '#FEF881',
    '#E6EE7A',
    '#A3D16E',
    '#78D3F8',
    '#7FA6F8',
    '#7E52F5',
    '#C45FF6',
    '#DE789D',
    '#F09286',
    '#F2A984',
    '#F6C983',
    '#F9DA85',
    '#FEF9A1',
    '#EBF29B',
    '#BADC94',
    '#A5E1FA',
    '#ADC5FA',
    '#AB8DF7',
    '#D696F8',
    '#E8A7BF',
    '#F4B8B1',
    '#F6C7AF',
    '#F9DAAE',
    '#FAE5AF',
    '#FEFBC0',
    '#F3F7BE',
    '#D2E7BA',
    '#D2EFFD',
    '#D6E1FC',
    '#D6C9FA',
    '#E9CBFB',
    '#F3D4DF',
    '#F9DCD9',
    '#FAE3D8',
    '#FCECD7',
    '#FDF2D8',
    '#FEFCE0',
    '#F7FADE',
    '#E3EDD6',
    '#FF0000',
    '#800000',
    '#FFFF00',
    '#808000',
    '#00FF00',
    '#008000',
    '#00FFFF',
    '#008080',
    '#0000FF',
    '#000080',
    '#FF00FF',
    '#800080'
  ]

  const isSmallScreen = useMediaQuery('(max-width: 600px)')

  const addRecentColor = (color) => {
    const copiedRecentColors = [...recentColors]
    if (copiedRecentColors.some((item) => item.color === color)) {
      console.log('color already exists')
    } else {
      copiedRecentColors.unshift({ color })
      copiedRecentColors.pop()
      setRecentColors(copiedRecentColors)
      sessionStorage.setItem('recentColors', JSON.stringify(copiedRecentColors))
    }
  }

  const checkIsSlotInGroup = (data) => {
    let isSlotInGroup = false
    data[0].groups.forEach((group, index) => {
      if (selectedLabelsArray.some((label) => group.labels.includes(label))) {
        isSlotInGroup = true
      }
    })
    return isSlotInGroup
  }

  const getCurrentColor = () => {
    let currentColor = null
    if (selectedLabelsArray !== []) {
      const isSlotInGroup = checkIsSlotInGroup(data)

      if (isSlotInGroup === true) {
        currentColor = data[0].groups.find((e) => e.id === selectedGroup).color
      } else {
        if (selectedLabelsArray.length === 1) {
          const index = data[0].data.findIndex(
            (e) => e.id === selectedLabelsArray[0]
          )
          currentColor = data[0].data[index].color
        } else {
          const groupIds = data[0].groups.map((group) => group.id)
          const missingIds = selectedLabelsArray.filter(
            (labelId) => !groupIds.includes(labelId)
          )

          missingIds.forEach((missingId) => {
            const slotIndex = data[0].data.findIndex(
              (slot) => slot.id === missingId
            )
            if (slotIndex !== -1) {
              currentColor = data[0].data[slotIndex].color
            }
          })
        }
      }
    }
    return currentColor
  }

  const currentColor = getCurrentColor()

  useEffect(() => {
    if (currentColor) {
      setColor(currentColor)
    }
    if (recentColorsLocal) {
      setRecentColors(JSON.parse(recentColorsLocal))
    }
  }, [])

  const saveColor = (color) => {
    /* UPDATE GLOBAL DATA STATE */
    const newData = JSON.parse(JSON.stringify(data))
    const activeColor = color

    if (selectedLabelsArray !== []) {
      const isSlotInGroup = checkIsSlotInGroup(newData)

      if (isSlotInGroup === true) {
        newData[0].groups.find((e) => e.id === selectedGroup).color =
          activeColor
      } else {
        if (selectedLabelsArray.length === 1) {
          const index = newData[0].data.findIndex(
            (e) => e.id === selectedLabelsArray[0]
          )
          newData[0].data[index].color = activeColor
        } else {
          const groupIds = newData[0].groups.map((group) => group.id)
          const missingIds = selectedLabelsArray.filter(
            (labelId) => !groupIds.includes(labelId)
          )

          missingIds.forEach((missingId) => {
            const slotIndex = newData[0].data.findIndex(
              (slot) => slot.id === missingId
            )
            if (slotIndex !== -1) {
              newData[0].data[slotIndex].color = activeColor
            }
          })
        }
      }
    }

    setData(newData)
    clearSelectedLabels([])
    setColorPopupOpen(false)
    addRecentColor(activeColor)
  }

  const getColorPicker = () => {
    switch (mode) {
      case BASIC:
        return (
          <Box>
            <CompactPicker
              color={color}
              onChange={(color) => setColor(color.hex)}
              colors={colorsArray}
            />
          </Box>
        )
      case ADVANCED:
        return (
          <SketchPicker
            color={color}
            width={isSmallScreen ? '250px' : '330px'}
            onChange={(color) => setColor(color.hex)}
            disableAlpha
          />
        )
      default:
        return null
    }
  }

  return (
    <Box className="popup" sx={{ height: window.innerHeight }}>
      <Box className="popupBlock">
        <Box className="popupClose">
          <Typography sx={{ fontFamily: 'Abel', marginBottom: '10px' }}>
            Color Picker
          </Typography>
          <img
            src={close}
            alt="Close"
            onClick={(e) => setColorPopupOpen(false)}
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px'
          }}
        >
          <Button
            variant={mode === BASIC ? 'contained' : 'outlined'}
            onClick={() => setMode(BASIC)}
            style={{ marginRight: '10px', borderRadius: '12px' }}
          >
            Basic
          </Button>
          <Button
            variant={mode === ADVANCED ? 'contained' : 'outlined'}
            onClick={() => setMode(ADVANCED)}
            style={{ marginLeft: '10px', borderRadius: '12px' }}
          >
            Advanced
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '330px',
            marginBottom: '20px'
          }}
        >
          {getColorPicker()}
        </Box>
        <Box>
          <Typography
            sx={{ fontFamily: 'Abel', color: '#FFFFFF', marginBottom: '8px' }}
          >
            RECENTLY USED
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {recentColors.map(({ color: itemColor }, index) => {
              const isActiveColor = currentColor === itemColor
              return (
                <Box key={index}
                  sx={{
                    ...{
                      width: '26px',
                      height: '26px',
                      cursor: itemColor ? 'pointer' : 'default',
                      backgroundColor: itemColor,
                      borderColor: isActiveColor
                        ? '#FFFFFF'
                        : itemColor
                          ? 'transparent'
                          : '#000000',
                      borderWidth: '1px',
                      borderStyle: 'solid'
                    }
                  }}
                  onClick={() => itemColor && saveColor(itemColor)}
                />
              )
            })}
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '6px',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography
              sx={{ fontFamily: 'Abel', color: '#FFFFFF', marginBottom: '8px' }}
            >
              CURRENT COLOR
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <Box
                sx={{
                  backgroundColor: currentColor,
                  width: '26px',
                  height: '26px',
                  marginRight: '6px'
                }}
              ></Box>
              <Box
                sx={{
                  backgroundColor: '#D9D9D9',
                  height: '26px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '4px',
                  paddingLeft: '4px'
                }}
              >
                <Typography sx={{ fontSize: '13px' }}>
                  {currentColor.toUpperCase()}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Button
            variant="outlined"
            sx={{
              borderColor: '#FFFFFF',
              color: '#FFFFFF',
              borderRadius: '8px',
              '&:hover': {
                color: '#FFFFFF'
              }
            }}
            className="selectButton"
            onClick={(e) => saveColor(color)}
          >
            Select
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ColorSelect
