import { useEffect, useState, useRef, useCallback } from 'react'
import './LogoSelect.css'
import close from '../../images/close.svg'
import save from '../../images/save.svg'
import upload from '../../images/upload.svg'
import imageCompression from 'browser-image-compression'
import Loading from '../../images/loading_black.svg'
import { setCanvasPreview } from '../../utils/setCanvasPreview'
import ReactCrop, { centerCrop, makeAspectCrop } from '../../utils/react-image-crop'
import { Button, Checkbox, Typography, Modal, Box } from '@mui/material'

function LogoSelect (props) {
  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState()
  const [termsCheckbox, setTermsCheckbox] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [isVerticalPhoto, setIsVerticalPhoto] = useState(false)
  const previewCanvasRef = useRef(null)
  const uploadInputRef = useRef(null)
  const imgRef = useRef(null)

  const isSafari = navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome|chromium|crios/i)

  const handleImageLoaded = (e) => {
    const { width, height } = e.currentTarget
    const crop = makeAspectCrop(
      {
        unit: 'px',
        width: 150,
        height: 150
      },
      1,
      width,
      height
    )
    const centeredCrop = centerCrop(crop, width, height)
    setCrop(centeredCrop)
    setIsLoadingImage(false)
  }

  const saveLogo = (logotype) => {
    if (!imageSrc && !props.data[0].logotype) {
      const newData = [...props.data]
      newData[0].amount = 24.99
      newData[0].logotype = ''
      props.setData(newData)
      props.setLogoUploadOpen(false)
      return
    }

    if (!imageSrc && props.data[0].logotype) {
      props.setLogoUploadOpen(false)
      return
    }

    if (logotype === null) {
      alert('Please select logo')
    } else {
      const newData = [...props.data]

      if (newData[0].logotype === '') {
        newData[0].amount = 29.99
      }

      newData[0].logotype = logotype

      props.setLogoUploadOpen(false)
      props.setData(newData)
    }
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const dataBlob = previewCanvasRef.current.toDataURL('image/jpeg')
      saveLogo(dataBlob)
    } catch (e) {
      console.error(e)
    }
  }, [imageSrc])

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const fileSize = e.target.files[0].size / 1024
      const file = e.target.files[0]

      if (fileSize > 1500) {
        return alert('File size should be less than 1.5MB')
      }

      const imgURL = URL.createObjectURL(file)
      const img = new Image()
      img.src = imgURL

      img.onload = async () => {
        if (img.width > 2048 || img.height > 2048) {
          setIsLoadingImage(false)
          return alert('Image size should be less than 2048x2048px')
        }
        if (img.width < 100 || img.height < 100) {
          setIsLoadingImage(false)
          return alert('Image size should be more than 100x100px')
        }
        if (img.width < img.height) {
          setIsVerticalPhoto(true)
        }

        URL.revokeObjectURL(imgURL)
        setIsLoadingImage(true)

        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }

        const compressedFile = await imageCompression(file, options)
        const imageDataUrl = await readFile(compressedFile)

        setImageSrc(imageDataUrl)
      }
    }
  }

  useEffect(() => {
    if (props.logoUploadOpen === true) {
      document.getElementById('uploadInput').click()
    }
  }, [])

  const removeLogo = () => {
    const newData = [...props.data]
    newData[0].logotype = ''
    newData[0].amount = 24.99
    props.setData(newData)
  }

  useEffect(() => {
    if (imgRef.current) {
      setCanvasPreview(imgRef.current, previewCanvasRef.current, crop)
    }
  }, [imgRef.current])

  const handleCropChange = (val) => {
    setCrop(val)
    setCanvasPreview(imgRef.current, previewCanvasRef.current, val)
  }

  const getIsDisbaledButton = () => {
    if (isLoadingImage) {
      return true
    }
    if (!imageSrc || props.data[0].logotype) {
      return false
    }
    if (!termsCheckbox) {
      return true
    }
  }

  return (
    <Modal open className="popup">
      <Box className="popupBlock">
        <Box className="popupClose popupLogoClose">
          <img
            src={close}
            alt="Close"
            onClick={(e) => {
              props.setLogoUploadOpen(false)
            }}
          />
        </Box>
        <Box className="popupContent popupLogoContent">
          <p className="logoTitle">Add your logo</p>
          <Box className="custom-logo-wr">
            <Box sx={{
              position: 'relative',
              width: '250px',
              height: '250px',
              overflow: 'hidden',
              background: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '12px'
            }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {isLoadingImage && (
                  <Box
                    sx={{
                      width: '250px',
                      height: '250px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#FFFFFF',
                      borderRadius: '12px'
                    }}
                  >
                    <img src={Loading} alt="loading" style={{ width: 50, height: 50 }} />
                  </Box>
                )}
                {imageSrc
                  ? (
                  <ReactCrop
                    crop={crop}
                    onChange={handleCropChange}
                    keepSelection
                    className={isVerticalPhoto ? 'cropp-height' : 'cropp-width'}
                    aspect={1}
                    minWidth={100}
                    minHeight={100}
                  >
                    <img
                      ref={imgRef}
                      src={imageSrc}
                      alt="upload-icon"
                      style={{ width: isVerticalPhoto ? 'unset' : '100%', height: isVerticalPhoto ? '100%' : 'unset' }}
                      onLoad={handleImageLoaded}
                    />
                  </ReactCrop>
                    )
                  : (
                  <Box className="imgPlaceholder" sx={{ display: isLoadingImage ? 'none' : 'block' }}>
                    {props.data[0].logotype
                      ? (
                      <img src={props.data[0].logotype} style={{ height: '100%' }} alt="upload-icon" className="upload-icon" />
                        )
                      : (
                      <img src={upload} alt="upload-icon" className="upload-icon" />
                        )}
                    <input type="file" accept="image/*" ref={uploadInputRef} onChange={onFileChange} className="custom-logo-input" />
                  </Box>
                    )}
              </Box>
            </Box>
          </Box>
          <Box>
            {props.data[0].logotype && (
              <Button onClick={removeLogo} sx={{ color: '#0080ff', fontSize: '12px', padding: '0px 6px', marginTop: '10px' }}>
                Remove Logo
              </Button>
            )}
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                display: 'none',
                objectFit: 'contain',
                width: '150px',
                height: '150px'
              }}
            />
            {isSafari && (
              <Typography sx={{ color: '#FFFFFF', fontFamily: 'Abel', marginTop: '10px' }}>
                {"If you're using Safari and have issues uploading the logo, please use Chrome"}
              </Typography>
            )}
            <p className="logoDescription">
              Copyright and Trademark Notice - By checking the box below, you agree and confirm that you own or have the necessary rights to
              use the images, logos, text, and/or trademarks in the file you are uploading and indemnify Trace Audio LLC.
            </p>
          </Box>
          <Box sx={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Checkbox sx={{ padding: 0, paddingRight: '10px', color: '#FFFFFF' }} onChange={(e) => setTermsCheckbox(e.target.checked)} />
            <Typography sx={{ color: '#FFFFFF', fontFamily: 'Abel' }}>I agree to the above statement</Typography>
          </Box>
          <Box>
            <p className="logoDescription">
              *For best results upload images with a clear background, or white outline for contrast if the logo is black.
            </p>
          </Box>
        </Box>
        <Box className="popupBottomButton">
          <Button
            className="selectButton logoSelectButton"
            onClick={showCroppedImage}
            variant="outlined"
            sx={{
              color: '#FFFFFF',
              borderRadius: '10px',
              backgroundColor: '#0080ff',
              '&:hover': {
                color: '#FFFFFF'
              },
              '&:disabled': {
                color: '#FFFFFF',
                opacity: 0.4,
                borderColor: 'gray',
                backgroundColor: 'transparent'
              }
            }}
            disabled={getIsDisbaledButton()}
          >
            <img src={save} style={{ stroke: '#FFFFFF' }} alt="Save" />
            <p>Save</p>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default LogoSelect
