import React, { Suspense, lazy, useEffect, useState } from 'react'
import Menu from './Components/Menu/Menu'
import ModelSelect from './Pages/Steps/ModelSelect/ModelSelect'
import BottomMenu from './Components/Menu/BottomMenu'
import LabelsStep from './Pages/Steps/Labels/LabelsStep'
import ColorStep from './Pages/Steps/Color/ColorStep'
import LogoStep from './Pages/Steps/Logo/LogoStep'
import PreviewStep from './Pages/Steps/Preview/PreviewStep'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './Pages/Login'
import Admin from './Pages/Admin'
import AuthCheck from './hoc/AuthCheck'
import Signup from './Pages/Signup'
import { Box } from '@mui/material'
import AddToCart from './Pages/AddToCart'
import ForgotPassword from './Pages/ForgotPassword'
import EditFromCart from './Pages/EditFromCart'

const Design = lazy(() => import('./Pages/Design'))
const Preview = lazy(() => import('./Pages/Preview'))
const Cart = lazy(() => import('./Pages/Admin/Cart/Cart'))
const SaveDesign = lazy(() => import('./Pages/SaveDesign'))

const MainRouter = () => {
  const [activeStep, setActiveStep] = useState(JSON.parse(sessionStorage.getItem('step')) || 0)
  const [activePage, setActivePage] = useState('')
  const [updateCart, updateCartData] = useState(false)
  const [data, setData] = useState([
    {
      settings: [
        {
          fontClass: '',
          fontName: ''
        }
      ],
      logotype: '',
      amount: 0,
      data: [],
      groups: [],
      isEditing: false,
      isEditFromCart: false
    }
  ])

  const [isTemplateSelected, setIsTemplateSelected] = useState(false)
  const template = JSON.parse(window.sessionStorage.getItem('template'))

  useEffect(() => {
    if (activeStep === 0) {
      clearAllData()
    }
    updateCartData(!updateCart)
  }, [activeStep])

  function clearAllData () {
    setIsTemplateSelected(false)
    window.sessionStorage.removeItem('template')
    window.sessionStorage.removeItem('data')
    window.sessionStorage.removeItem('selectedBrand')

    setData([
      {
        settings: [
          {
            fontClass: 'openSans',
            fontName: 'Open Sans'
          }
        ],
        logotype: '',
        amount: 0,
        data: [],
        groups: []
      }
    ])
  }

  function readDataFromsessionStorage () {
    if (activeStep !== 0) {
      const dataFromsessionStorage = JSON.parse(sessionStorage.getItem('data'))
      const step = JSON.parse(sessionStorage.getItem('step'))
      if (dataFromsessionStorage !== null) {
        setIsTemplateSelected(true)
        setData(dataFromsessionStorage)
        setActiveStep(step)
      }
    }
  }
  useEffect(() => {
    if (activeStep !== 0) {
      readDataFromsessionStorage()
    }
    updateCartData(!updateCart)
  }, [activeStep])

  useEffect(() => {
    if (activeStep !== 0) {
      try {
        sessionStorage.setItem('data', JSON.stringify(data))
      } catch (e) {
        if (e.name === 'QuotaExceededError') {
          console.log('QuotaExceededError')
        }
      }
    }
    updateCartData(!updateCart)
  }, [data])

  const [cartItems, setCartItems] = useState('')

  function execUpdateCart () {
    updateCartData(!updateCart)
  }

  return (
    <Box className="wrapper">
      <BrowserRouter>
        <Box className="contentPart">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <AuthCheck>
                  <Admin
                    activePage={activePage}
                    setActivePage={setActivePage}
                    setActiveStep={setActiveStep}
                    setData={setData}
                    clearAllData={clearAllData}
                  />
                </AuthCheck>
              }
            />
            <Route exact path="/login" element={<Login setActiveStep={setActiveStep} setData={setData} />} />
            <Route exact path="/signup" element={<Signup setActiveStep={setActiveStep} setData={setData} />} />
            <Route
              exact
              path="/cart-edit/*"
              element={
                <Suspense>
                  <EditFromCart setActiveStep={setActiveStep} />
                </Suspense>
              }
            />
            <Route
              exact
              path="/preview/*"
              element={
                <Suspense>
                  <Preview setActiveStep={setActiveStep} />
                </Suspense>
              }
            />
            <Route
              exact
              path="/forgot-password/*"
              element={
                <Suspense>
                  <ForgotPassword />
                </Suspense>
              }
            />
            <Route
              exact
              path="/design/*"
              element={
                <Suspense>
                  <Design setActiveStep={setActiveStep} />
                </Suspense>
              }
            />
            <Route
              exact
              path="/save-design/*"
              element={
                <Suspense>
                  <SaveDesign setActiveStep={setActiveStep} />
                </Suspense>
              }
            />
            <Route
              exact
              path="/admin"
              element={
                <AuthCheck>
                  <Admin
                    activePage={activePage}
                    setActivePage={setActivePage}
                    setActiveStep={setActiveStep}
                    setData={setData}
                    clearAllData={clearAllData}
                  />
                </AuthCheck>
              }
            />
            <Route
              exact
              path="/add-to-cart/*"
              element={
                <AuthCheck>
                  <AddToCart setActiveStep={setActiveStep} />
                </AuthCheck>
              }
            />
            <Route
              exact
              path="/cart"
              element={
                <Suspense>
                  <Cart
                    updateCartData={execUpdateCart}
                    activePage={activePage}
                    setActivePage={setActivePage}
                    setActiveStep={setActiveStep}
                    setCartItems={setCartItems}
                  />
                </Suspense>
              }
            />
            <Route
              exact
              path="/admin/*"
              element={
                <AuthCheck>
                  <Admin
                    activePage={activePage}
                    setActivePage={setActivePage}
                    setActiveStep={setActiveStep}
                    setData={setData}
                    clearAllData={clearAllData}
                  />
                </AuthCheck>
              }
            />
            <Route
              exact
              path="/create"
              element={
                <>
                  {
                    <Menu
                      setActiveStep={setActiveStep}
                      updateCart={updateCartData}
                      cartItems={cartItems}
                      activeStep={activeStep}
                      activePage={activePage}
                      setActivePage={setActivePage}
                      clearAllData={clearAllData}
                      template={template}
                    />
                  }
                  {activeStep === 0 && (
                    <ModelSelect
                      setActiveStep={setActiveStep}
                      data={data}
                      setData={setData}
                      setIsTemplateSelected={setIsTemplateSelected}
                    />
                  )}
                  {activeStep === 1 && <ColorStep data={data} setData={setData} activeStep={activeStep} />}
                  {activeStep === 2 && <LabelsStep data={data} setData={setData} activeStep={activeStep} />}
                  {activeStep === 3 && <LogoStep data={data} setData={setData} activeStep={activeStep} />}
                  {activeStep >= 4 && (
                    <PreviewStep
                      data={data}
                      setData={setData}
                      clearAllData={clearAllData}
                      setActiveStep={setActiveStep}
                      activeStep={activeStep}
                    />
                  )}
                  {activeStep !== 0
                    ? (
                    <BottomMenu
                      setActiveStep={setActiveStep}
                      activeStep={activeStep}
                      isTemplateSelected={isTemplateSelected}
                      totalAmount={data[0].amount}
                    />
                      )
                    : null}
                </>
              }
            />
          </Routes>
        </Box>
      </BrowserRouter>
    </Box>
  )
}

export default MainRouter
