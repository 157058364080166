import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import instance from '../api/instance'
import { isEmailValid, onlyNumbers } from '../helpers/regExp-validation'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [loadingSendCodeOnEmail, setLoadingSendCodeOnEmail] = useState(false)
  const [loadingSendNewPassword, setLoadingSendNewPassword] = useState(false)
  const [firstStepErrors, setFirstStepErrors] = useState(null)
  const [secondStepErrors, setSecondStepErrors] = useState(null)
  const [isSecondStep, setIsSecondStep] = useState(false)

  const navigate = useNavigate()

  const handleSendCodeOnEmail = async () => {
    try {
      setLoadingSendCodeOnEmail(true)
      const response = await instance.post('/v1/generatePasswordReset', { email })
      if (response.status === 200) {
        setIsSecondStep(true)
      }
      setLoadingSendCodeOnEmail(false)
    } catch (err) {
      console.error(err)
      setFirstStepErrors(err.response.data.message)
      setLoadingSendCodeOnEmail(false)
    }
  }

  const handleSendNewPassword = async () => {
    try {
      setLoadingSendNewPassword(true)
      const response = await instance.post('/v1/resetPassword', { resetCode: code, newPassword })
      if (response.status === 200) {
        setIsSecondStep(false)
        navigate('/login')
      }
      setLoadingSendNewPassword(false)
    } catch (err) {
      console.error(err)
      setSecondStepErrors(err.response.data.message)
      setLoadingSendNewPassword(false)
    }
  }

  const handleChangeCode = (val) => {
    const isOnlyNumbers = onlyNumbers(val)
    if (val.length > 6 || !isOnlyNumbers) {
      return
    }
    setCode(val)
  }

  console.log(isEmailValid(email))

  const disabledSendCodeOnEmail = email.length < 6 || loadingSendCodeOnEmail || !isEmailValid(email)
  const disabledSendNewPassword = code.length < 6 || newPassword.length < 6 || loadingSendNewPassword

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '96vh', overflow: 'hidden' }}>
      <Box sx={{ width: '100%', maxWidth: '400px' }} >
      <h1>Reset password</h1>
      <p className="loginSubtitle">If you forgot your password, you can easily reset this</p>
      </Box>
      {isSecondStep
        ? (
        <Box sx={{ display: 'flex', maxWidth: '400px', width: '100%', flexDirection: 'column' }}>
          <Box sx={{ width: '100%', maxWidth: '400px' }} className="loginInput">
            <label htmlFor="code">Code</label>
            <input type="text" name="code" id="code" placeholder="000000" value={code} required onChange={(e) => handleChangeCode(e.target.value)} />

          </Box>
          <Box sx={{ width: '100%', maxWidth: '400px' }} className="loginInput">
            <label htmlFor="password">New Password</label>
            <input
            style={{ maxWidth: '400px', width: '100%' }}
              type="password"
              name="password"
              id="password"
              placeholder="password"
              value={newPassword}
              required
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Box>
          {secondStepErrors && <Typography sx={{ color: 'red', fontFamily: 'Abel' }} >{secondStepErrors}</Typography>}
          <Button variant="contained" disabled={disabledSendNewPassword} onClick={handleSendNewPassword}>
            Send New Password
          </Button>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '4px' }}>
              <Button onClick={handleSendCodeOnEmail} variant="text" sx={{ fontFamily: 'Abel', color: '#000000', textTransform: 'none' }}>
                Resend code
              </Button>
            </Box>
        </Box>
          )
        : (
        <Box sx={{ display: 'flex', width: '100%', maxWidth: '400px', flexDirection: 'column' }}>
          <Box sx={{ width: '100%', maxWidth: '400px' }} className="loginInput">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="mail@mail.com"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
            />
            {firstStepErrors && <Typography sx={{ color: 'red', fontFamily: 'Abel' }} >{firstStepErrors}</Typography>}
          </Box>
          <Button variant="contained" disabled={disabledSendCodeOnEmail} onClick={handleSendCodeOnEmail}>
            Send Code
          </Button>
        </Box>
          )}
    </Box>
  )
}

export default ForgotPassword
