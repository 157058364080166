import React, { useEffect, useState } from 'react'
import './PreviewStep.css'
import bookmark from '../../../images/bookmark.svg'
import credit_card from '../../../images/credit-card.svg'
import loadingBlack from '../../../images/loading_black.svg'
import axios from 'axios'
import { Navigate, useNavigate } from 'react-router-dom'
import loading from '../../../images/loading.svg'
import ViewCartPopup from '../../../Popups/ViewCartPopup/ViewCartPopup'
import { Button, Box, Typography } from '@mui/material'

const PreviewStep = (props) => {
  const [checkoutRedirect] = useState(null)
  const [saveButton, setSaveButton] = useState('Save to My Designs')
  const adminStatus = sessionStorage.getItem('adminStatus')
  const template = JSON.parse(sessionStorage.getItem('template'))
  const [orderLoading, setOrderLoading] = useState(false)

  const [saveDesignLoading, setSaveDesignLoading] = useState(false)
  const [viewCartPopupOpen, setViewCartPopupOpen] = useState(false)
  const [wantToSavePopupOpen, setWantToSavePopupOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [previewImage, setPreviewImage] = useState('')
  const [previewLoading, setPreviewLoading] = useState(false)
  const [isBigPreview, setIsBigPreview] = useState(false)
  const [attentionRedirectsOpen, setAttentionRedirectsOpen] = useState(false)

  const navigation = useNavigate()
  function getOrders () {
    const token = localStorage.getItem('token')
    let query = 'orders'
    let method = 'get'
    if (token == null) {
      method = 'post'
      query = 'localOrders'
    }
    const config = {
      method,
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com'}/api/v1/${query}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    if (query === 'localOrders') {
      config.data = JSON.parse(sessionStorage.getItem('localeItems'))
    }
    axios
      .request(config)
      .then((response) => {
        const arr = response.data.reverse()
        const filteredData = arr.filter((item) => item.Status === 1)

        /* get orders ids */

        const ids = filteredData.map((e) => e.Id)
        setSelectedItems(ids)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getOrders()
    const savedActiveStep = JSON.parse(sessionStorage.getItem('savedActiveStep'))
    if (savedActiveStep === 5) {
      setViewCartPopupOpen(true)
    }
  }, [])

  const sendData = (apiURL) => {
    const orderData = {
      DesignName: template.name,
      DesignId: template.id,
      DesignLogotype: props.data[0].logotype || null,
      DesignData: [
        {
          data: props.data[0].data.map((item) => {
            return item
          }),
          groups: props.data[0].groups,
          settings: props.data[0].settings
        }
      ],
      DesignDescription: template.DesignDescription,
      Amount: props.data[0].amount,
      Status: 1,
      Id: props.data[0].Id !== undefined ? props.data[0].Id : null
    }
    const data = new FormData()

    if (!props.data[0].logotype.includes('http')) {
      data.append('file', props.data[0].logotype)
    }

    data.append('data', JSON.stringify(orderData))

    const token = localStorage.getItem('token')

    const config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com'}${apiURL}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    setSaveDesignLoading(true)
    axios
      .request(config)
      .then((response) => {
        if (response.data === 'Design updated successfully') {
          setSaveButton('Your design is saved!')
          setSaveDesignLoading(false)
        } else if (response.data.designId) {
          const newData = [{ ...props.data[0], isEditing: true, Id: response.data.designId }]
          props.setData(newData)
          setSaveButton('Your design is saved!')
          setSaveDesignLoading(false)
        }
      })
      .catch((error) => {
        setSaveDesignLoading(false)
        console.log(error)
      })
  }

  const onCreateAnotherDesign = () => {
    setViewCartPopupOpen(false)
    setWantToSavePopupOpen(true)
  }

  const addAdminOrdersPaid = (orderId) => {
    const randomOrderNumber = Math.floor(Math.random() * 1000000000)
    const data = {
      line_items: [{ properties: [{ name: 'designOrderId', value: orderId }] }],
      contact_email: 'ben@traceaudio.com',
      order_number: randomOrderNumber,
      customer: {
        first_name: 'Ben',
        last_name: 'Sabin'
      },
      isGeneratedByAdmin: true
    }
    const token = localStorage.getItem('token')
    axios({
      url: `${process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com'}/api/v1/ordersPaid`,
      method: 'post',
      data,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => {
        setOrderLoading(false)
      })
      .catch((error) => {
        setOrderLoading(false)
        console.error('Error:', error)
      })
  }

  const addAdminOrder = () => {
    setOrderLoading(true)
    const orderData = {
      DesignName: template.name,
      DesignId: template.id,
      DesignLogotype: props.data[0].logotype || null,
      DesignData: [
        {
          data: props.data[0].data.map((item) => {
            return item
          }),
          groups: props.data[0].groups,
          settings: props.data[0].settings
        }
      ],
      DesignDescription: template.DesignDescription,
      Amount: props.data[0].amount,
      Status: 1,
      Id: props.data[0].Id !== undefined ? props.data[0].Id : null
    }

    const dataForm = new FormData()
    if (!props.data[0].logotype.includes('http')) {
      dataForm.append('file', props.data[0].logotype)
    }
    dataForm.append('data', JSON.stringify(orderData))
    const token = localStorage.getItem('token')

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com'}/api/v1/addOrder`,
      data: dataForm,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    axios
      .request(config)
      .then((response) => {
        if (response.data.orderId) {
          addAdminOrdersPaid(response.data.orderId)
        }
      })
      .catch((error) => {
        setOrderLoading(false)
        console.log(error)
      })
  }

  const getGeneratedPreview = async () => {
    setPreviewLoading(true)

    const orderData = {
      DesignName: template.name,
      DesignId: template.id,
      DesignLogotype: props.data[0].logotype || null,
      DesignData: [
        {
          data: props.data[0].data.map((item) => {
            return item
          }),
          groups: props.data[0].groups,
          settings: props.data[0].settings
        }
      ],
      DesignDescription: template.DesignDescription,
      Amount: props.data[0].amount,
      Status: 1,
      Id: props.data[0].Id !== undefined ? props.data[0].Id : null
    }
    const data = new FormData()
    if (!props.data[0].logotype.includes('http')) {
      data.append('file', props.data[0].logotype)
    }

    data.append('data', JSON.stringify(orderData))

    const config = {
      method: 'POST',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com'}/api/v1/generatePreview`,
      data,
      headers: {}
    }

    await axios
      .request(config)
      .then((response) => {
        setPreviewImage(`${process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com'}${response.data}`)
        setPreviewLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setPreviewLoading(false)
      })
  }

  useEffect(() => {
    if (props.data[0].data.length) {
      getGeneratedPreview()
    }
  }, [props.data[0].data, props.data[0].logotype, props.data[0].groups])

  return (
    <Box className="labelsStep finalStep">
      <Box className="stepTexts">
        <h1 className="main-title">Here Is Your Final Design!</h1>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <p>
            “All that hard work paid off! Now you can get back to making music and spend less time wondering where that dang compressor is.
          </p>
        </Box>
      </Box>
      <Box className="contentBlock">
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Box>
            {window.localStorage.getItem('token') !== null
              ? (
              <Button
                variant="contained"
                sx={{ borderRadius: '10px', height: '45px', backgroundColor: '#7A92BA' }}
                disabled={saveDesignLoading}
                onClick={(e) => {
                  sendData(
                    props.data[0].isEditing === true
                      ? props.data[0].isSharedDesign === true
                        ? '/api/v1/addDesign'
                        : '/api/v1/editDesign'
                      : '/api/v1/addDesign'
                  )
                }}
              >
                {saveDesignLoading === true ? <img style={{ marginRight: '6px', width: '20px' }} src={loading} alt="Loading" /> : null}
                <Typography sx={{ fontFamily: 'Abel', marginRight: '10px' }}>
                  {props.data[0].isEditing === true ? (props.data[0].isSharedDesign === true ? saveButton : 'Update Data') : saveButton}
                </Typography>
                <img src={bookmark} alt="Down" />
              </Button>
                )
              : (
              <Button
                variant="contained"
                sx={{ borderRadius: '10px', height: '45px', backgroundColor: '#7A92BA' }}
                disabled={saveDesignLoading}
                onClick={(e) => {
                  sessionStorage.setItem(
                    'savedDesign',
                    JSON.stringify({
                      ...props.data[0],
                      DesignId: template.id,
                      DesignName: template.name
                    })
                  )
                  sessionStorage.setItem('savedActiveStep', props.activeStep)
                  sessionStorage.setItem('savedTempate', template.id)
                  navigation('/login?redirect=save-design/' + template.id)
                }}
              >
                <Typography sx={{ fontFamily: 'Abel', marginRight: '10px' }}>Save to My Designs</Typography>
                <img src={bookmark} alt="bookmark" />
              </Button>
                )}
          </Box>
        </Box>
        <Box sx={{ height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {previewImage
            ? (
            <Box
              sx={{
                marginTop: '60px',
                height: '340px',
                display: 'flex',
                alignItems: 'center',
                overflow: 'hidden',
                width: '100%',
                backgroundColor: 'rgba(237, 237, 237, 1)',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px'
              }}
            >
              {isBigPreview
                ? (
                <Box
                  style={{
                    overflowX: 'scroll',
                    height: '334px',
                    width: '100%',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    position: 'relative',
                    paddingRight: '20px'
                  }}
                >
                  <Box sx={{ backgroundColor: 'rgba(237, 237, 237, 1)', width: '30px', height: '270px', position: 'absolute' }}></Box>
                  <img
                    onClick={() => setIsBigPreview(false)}
                    style={{ height: '270px', marginLeft: '-136px', marginRight: '20px', cursor: 'zoom-out' }}
                    src={previewImage}
                    alt="preview_step_image"
                  />
                </Box>
                  )
                : (
                <Box sx={{ width: '100%', position: 'relative', overflow: 'hidden', marginLeft: '-2.4%' }}>
                  <Box sx={{ width: '5.7%', height: '127px', backgroundColor: 'rgba(237, 237, 237, 1)', position: 'absolute' }}></Box>
                  <img
                    onClick={() => setIsBigPreview(true)}
                    style={{ width: '100%', cursor: 'zoom-in' }}
                    src={previewImage}
                    alt="preview_step_image"
                  />
                </Box>
                  )}
            </Box>
              )
            : (
            <img src={loadingBlack} style={{ width: '50px', height: '50px' }} alt="loading_black" />
              )}
        </Box>
        <Box sx={{ marginTop: '100px', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', gap: '30px' }}>
          <Box sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Typography sx={{ color: '#000000', fontFamily: 'Abel', marginBottom: '10px' }}>
              This product takes 2 to 3 business days to ship
            </Typography>
            {+adminStatus === 2 && (
              <Button variant="contained" disabled={orderLoading} onClick={addAdminOrder}>
                Generate
                {orderLoading && (
                  <img style={{ width: '20px', marginLeft: '10px', position: 'relative', left: '10px' }} src={loading} alt="Loading" />
                )}
              </Button>
            )}
            {+adminStatus !== 2 && (
              <button className="buttonWithIcon" onClick={() => setViewCartPopupOpen(true)}>
                <img src={credit_card} alt="Credit Cart" style={{ marginRight: '10px', marginLeft: '10px' }} />
                <p>{props.data[0].isEditFromCart === true ? 'Update order' : 'Add to Cart'}</p>
              </button>
            )}
          </Box>
        </Box>
      </Box>
      {checkoutRedirect && <Navigate to={'/cart'} />}
      {viewCartPopupOpen && (
        <ViewCartPopup
          onCreateAnotherDesign={onCreateAnotherDesign}
          onClose={() => setViewCartPopupOpen(false)}
          openAttentionPopup={() => setAttentionRedirectsOpen(true)}
          data={props.data}
          setActiveStep={props.setActiveStep}
          setData={props.setData}
          template={template}
        />
      )}
    </Box>
  )
}

export default PreviewStep
