import React, { Fragment, useEffect, useState } from 'react'
import templates from '../../../static/templates.json'
import './ModelSelect.css'
import axios from 'axios'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, useMediaQuery, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'

function ModelSelect (props) {
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [expanded, setExpanded] = useState(false)

  const isSmallScreen = useMediaQuery('(max-width:500px)')
  const templateBrands = [...new Set(templates.map((template) => template.brand))]

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    if (selectedTemplate !== null) {
      const data = [...props.data]

      const template = fetchData(data)

      template.then((result) => {})
    }
  }, [selectedTemplate])

  const fetchData = async (data) => {
    const price = await getTemplatePrice(selectedTemplate.name)
    if (price === undefined) return

    for (let i = 0; i < selectedTemplate.rows * selectedTemplate.labelsPerRow; i++) {
      if (
        (selectedTemplate.firstLarge === true && i === 0) ||
        selectedTemplate.labelsPerRow - 1 === i ||
        selectedTemplate.labelsPerRow * 2 - 1 === i ||
        selectedTemplate.labelsPerRow === i
      ) {
        data[0].data.push({
          id: i + 1,
          topLabel: '',
          middleLabel: '',
          bottomLabel: '',
          color: '#ffffff'
        })
      } else {
        data[0].data.push({
          id: i + 1,
          topLabel: '',
          bottomLabel: '',
          color: '#ffffff'
        })
      }
    }

    data[0].amount = 24.99
    props.setData(data)
    props.setIsTemplateSelected(true)
    window.sessionStorage.setItem('template', JSON.stringify(selectedTemplate))
    window.sessionStorage.setItem('step', 1)
    props.setActiveStep(1)
  }

  const getTemplatePrice = async (template) => {
    const token = window.localStorage.getItem('token')

    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com'}/api/v1/findShopifyProduct?template=${template}`,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const response = await axios.request(config)
    return response.data.price
  }

  useEffect(() => {
    const token = window.localStorage.getItem('token')

    if (token !== null) {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL || 'https://labelmaker.traceaudioserver.com'}/api/v1/protect`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

      axios
        .request(config)
        .then((response) => {
          console.log('VALID TOKEN')
        })
        .catch((error) => {
          if (error.response.status === 403) {
            window.sessionStorage.removeItem('token')

            console.log('INVALID TOKEN')
            window.location.pathname = '/login'
          }
        })
    }
  }, [])

  return (
    <Box className="modelSelect" sx={{ paddingBottom: isSmallScreen ? '30px' : '70px' }}>
      <Box className="stepTexts">
        <h1>Select Your Model</h1>
        <h2>Congratulations! Your journey to a more organized patchbay starts here. Pick a patchbay model to begin designing.</h2>
      </Box>
      {templateBrands.map((brand) => (
        <Accordion key={brand} expanded={expanded === brand} onChange={handleChange(brand)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            <Typography sx={{ fontFamily: 'Abel', fontWeight: 700 }}>{brand}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="templatesList">
              {templates.map((template, index) => {
                if (template.brand !== brand) return null
                return (
                  <Fragment key={index}>
                    <p style={{ textAlign: 'left' }}>{template.name}</p>
                    <Box
                      className="template"
                      sx={{ marginBottom: '0px' }}
                      key={index}
                      onClick={(e) => {
                        setSelectedTemplate(template)
                      }}
                    >
                      <Box className="templateFilter"></Box>
                      <img src={template.image} alt={template.name} />
                    </Box>
                  </Fragment>
                )
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}

export default ModelSelect
