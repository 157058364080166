import { Link } from 'react-router-dom'
import PlusButton from '../../../images/plus_button.svg'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import axios from 'axios'
import { LoadingButton } from '@mui/lab'
import DesignBlock from '../MyDesigns/DesignBlock'

const RetrieveDesign = ({ setActiveStep }) => {
  const [designs, setDesigns] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [requestMade, setRequestMade] = useState(false)
  const [error, setError] = useState(false)

  const getDesigns = async () => {
    setLoading(true)
    setError(false)
    setRequestMade(false)
    const endOfEndpoint = search.includes('@') ? 'designs' : 'design'

    await axios
      .get(`https://labelmaker.traceaudioserver.com/api/v1/${search}/${endOfEndpoint}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then((res) => {
        setDesigns(res.data.designs)
        setRequestMade(true)
      })
      .catch((err) => {
        console.log(err)
        setError(true)
      })
    setLoading(false)
  }

  return (
    <Box className="myDesigns" sx={{ width: '100%' }}>
      <Box className="myDesignsHeader">
        <p>Retrieve designs</p>
        <Link to="/create">
          <img src={PlusButton} style={{ marginLeft: '6px' }} alt="Plus" onClick={(e) => setActiveStep(0)} />
        </Link>
      </Box>
      {designs.length
        ? (
        <>
          {designs.map((design, index) => (
            <DesignBlock key={index} data={design} setActiveStep={setActiveStep} disableDelete={true} />
          ))}
        </>
          )
        : (
        <Box sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '40%', maxWidth: '500px', marginTop: '10%' }}>
            {error ||
              (!designs.length && requestMade)
              ? (
                <Box>
                  <Typography sx={{ marginBottom: '10px', textAlign: 'center', fontFamily: 'Abel', fontSize: '20px', fontWeight: 400 }}>{'We couldn\'t find any designs for your query.'}</Typography>
                  <Typography sx={{ marginBottom: '10px', textAlign: 'center', fontFamily: 'Abel', fontSize: '20px', fontWeight: 400 }}>Please try again.</Typography>
                </Box>
                )
              : null}
            <TextField variant="outlined" label="Email or Design ID" value={search} InputLabelProps={{
              style: {
                fontFamily: 'Abel'
              }

            }} InputProps={{
              slotProps: {
                input: {
                  sx: {
                    fontFamily: 'Abel'
                  }
                }
              }
            }} sx={{ fontFamily: 'Abel' }} onChange={(e) => setSearch(e.target.value)} />
            <LoadingButton loading={loading} variant="contained" sx={{ marginTop: '20px', fontFamily: 'Abel' }} disabled={!search.length} onClick={getDesigns}>
              Get Design
            </LoadingButton>
          </Box>
        </Box>
          )}
    </Box>
  )
}

export default RetrieveDesign
