import React, { useEffect, useState } from 'react'
import '../Label/Label.css'
import './LabelFinal.css'
import select_icon from '../../../images/select_icon.svg'
import double from '../../../images/double.svg'
import LabelNumbering from '../../LabelNumbering/LabelNumbering'
import fontColorContrast from 'font-color-contrast'

function LabelFinal ({ middleLine, globalData, data, fontClass, index }) {
  const template = JSON.parse(window.sessionStorage.getItem('template'))

  const [fontColor, setFontColor] = useState('')
  const [isLabelInGroup, setIsLabelInGroup] = useState(false)
  const [firstID, setFirstID] = useState(null)
  const [groupData, setGroupData] = useState([
    {
      groupRowCount: 0,
      groupRowItems: 0,
      secondRowStart: null
    }
  ])

  const [isBigLabel, setIsBigLabel] = useState(false)
  const [isFakeLabel, setIsFakeLabel] = useState(false)

  const isSecondRowItem = data.id > globalData[0].data.length / 2
  const centerOfDataList = Math.ceil(globalData[0].data.length / 2 + 1)

  const isSamsonTemplate = 'samson_s_patch_plus'
  const isBehringerTemplate = 'behringer_px3000'
  const isDBXTemplate = 'dbx_pb_48'
  const isNeutrikTemplate = 'neutrik_nys_spp_l1'
  const isArtTemplate = 'art_p48'
  const isArtXlrTemplate = 'art_p16_xlr'
  const isBlackLionPbrXlrTemplate = 'black_lion_pbr_xlr'
  const isBlackLionPbrTrsTrs3 = 'black_lion_pbr_trs_trs3'
  const isBlackLionPbrTrsBt = 'black_lion_pbr_trs_bt'

  const getCircleSize = () =>
    ({
      [isSamsonTemplate]: 'samsonCircle',
      [isBehringerTemplate]: 'behringerCircle',
      [isDBXTemplate]: 'dbxCircle',
      [isNeutrikTemplate]: 'neutrikCircle',
      [isArtTemplate]: 'artCircle'
    }[template.id])

  const getBigLabelPosition = () => {
    if (template.rows === 1) {
      return template.firstRowBigLabelPosition
    } else {
      if (isSecondRowItem) {
        return template.secondRowBigLabelPosition
      } else {
        return template.firstRowBigLabelPosition
      }
    }
  }

  const getBottomBigLabelStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonBottomBigLabel',
      [isBehringerTemplate]: 'behringerBottomBigLabel',
      [isDBXTemplate]: 'dbxBottomBigLabel',
      [isNeutrikTemplate]: 'neutrikBottomBigLabel',
      [isArtTemplate]: 'artBottomBigLabel',
      [isArtXlrTemplate]: 'artXlrBottomBigLabel',
      [isBlackLionPbrTrsTrs3]: 'blackLionPbrTrsTrs3BottomBigLabel',
      [isBlackLionPbrTrsBt]: 'blackLionPbrTrsBtBottomBigLabel'
    }[template.id])

  const getTopBigLabelStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonTopBigLabel',
      [isBehringerTemplate]: 'behringerTopBigLabel',
      [isDBXTemplate]: 'dbxTopBigLabel',
      [isNeutrikTemplate]: 'neutrikTopBigLabel',
      [isArtTemplate]: 'artTopBigLabel',
      [isArtXlrTemplate]: 'artXlrTopBigLabel'
    }[template.id])

  const getTopLabelFirstRowStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonTopLabelFirstRow',
      [isBehringerTemplate]: 'behringerTopLabelFirstRow',
      [isDBXTemplate]: 'dbxTopLabelFirstRow',
      [isNeutrikTemplate]: 'neutrikTopLabelFirstRow',
      [isArtTemplate]: 'artTopLabelFirstRow',
      [isArtXlrTemplate]: 'artXlrTopLabelFirstRow',
      [isBlackLionPbrXlrTemplate]: 'blackLionPbrXlrTopLabelFirstRow',
      [isBlackLionPbrTrsTrs3]: 'blackLionPbrTrsTrs3TopLabelFirstRow',
      [isBlackLionPbrTrsBt]: 'blackLionPbrTrsBtTopLabelFirstRow'
    }[template.id])

  const getBottomLabelFirstRowStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonBottomLabelFirstRow',
      [isBehringerTemplate]: 'behringerBottomLabelFirstRow',
      [isDBXTemplate]: 'dbxBottomLabelFirstRow',
      [isNeutrikTemplate]: 'neutrikBottomLabelFirstRow',
      [isArtTemplate]: 'artBottomLabelFirstRow',
      [isArtXlrTemplate]: 'artXlrBottomLabelFirstRow',
      [isBlackLionPbrXlrTemplate]: 'blackLionPbrXlrBottomLabelFirstRow',
      [isBlackLionPbrTrsTrs3]: 'blackLionPbrTrsTrs3BottomLabelFirstRow',
      [isBlackLionPbrTrsBt]: 'blackLionPbrTrsBtBottomLabelFirstRow'
    }[template.id])

  const getTopLabelSecondRowStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonTopLabelSecondRow',
      [isBehringerTemplate]: 'behringerTopLabelSecondRow',
      [isDBXTemplate]: 'dbxTopLabelSecondRow',
      [isNeutrikTemplate]: 'neutrikTopLabelSecondRow',
      [isArtTemplate]: 'artTopLabelSecondRow',
      [isBlackLionPbrXlrTemplate]: 'blackLionPbrXlrTopLabelFirstRow',
      [isBlackLionPbrTrsTrs3]: 'blackLionPbrTrsTrs3TopLabelSecondRow',
      [isBlackLionPbrTrsBt]: 'blackLionPbrTrsBtTopLabelSecondRow'
    }[template.id])

  const getBottomLabelSecondRowStyle = () =>
    ({
      [isSamsonTemplate]: 'samsonBottomLabelSecondRow',
      [isBehringerTemplate]: 'behringerBottomLabelSecondRow',
      [isDBXTemplate]: 'dbxBottomLabelSecondRow',
      [isNeutrikTemplate]: 'neutrikBottomLabelSecondRow',
      [isArtTemplate]: 'artBottomLabelSecondRow',
      [isBlackLionPbrXlrTemplate]: 'blackLionPbrXlrBottomLabelFirstRow',
      [isBlackLionPbrTrsTrs3]: 'blackLionPbrTrsTrs3BottomLabelSecondRow',
      [isBlackLionPbrTrsBt]: 'blackLionPbrTrsBtBottomLabelSecondRow'
    }[template.id])

  const getBigLabelWidth = () => {
    if (template.id === isBlackLionPbrXlrTemplate) {
      if (groupData.containFakeLabels) {
        return groupData.groupRowItems * 167 - 108 + 'px'
      }
      if (groupData.containedLeftFakeLabels) {
        return groupData.groupRowItems * 167 + 58 + 'px'
      }
      if (groupData.containedRightFakeLabels) {
        return groupData.groupRowItems * 167 - 167 + 'px'
      }
      return groupData.groupRowItems * 167 + 'px'
    }
    if (template.id === isBlackLionPbrTrsBt || template.id === isBlackLionPbrTrsTrs3) {
      if (groupData.containedLeftFakeLabels) {
        return groupData.groupRowItems * 118 + 58 + 'px'
      }
      if (groupData.containedRightFakeLabels) {
        return groupData.groupRowItems * 118 - 118 + 'px'
      }
      if (groupData.containFakeLabels) {
        return groupData.groupRowItems * 118 - 30 + 'px'
      }
      return groupData.groupRowItems * 118 + 'px'
    }
    if (groupData.containFakeLabels) {
      return groupData.groupRowItems * 110 - 80 + 'px'
    }
    if (isBigLabel) {
      return groupData.groupRowItems * 110 + 'px'
    }
    if (!isBigLabel) {
      return groupData.groupRowItems * 110 + 'px'
    }
  }

  const getLabelNumber = (id) => {
    if (template.id === isBehringerTemplate) {
      if (data.id > 1 && data.id < template.labelsPerRow) {
        return id - 1
      }
      if (data.id > template.labelsPerRow + 1) {
        return id - 3
      }
    }
    return id
  }

  const labelNumber = getLabelNumber(data.id)
  const bigLabelWidth = getBigLabelWidth()

  const [labelStyle, setLabelStyle] = useState({
    backgroundColor: '',
    borderTopLeftRadius: '',
    borderTopRightRadius: '',
    borderBottomLeftRadius: '',
    borderBottomRightRadius: ''
  })

  useEffect(() => {
    const currentID = data.id

    const groupData = [...globalData[0].groups].find((e) => e.labels.indexOf(currentID) !== -1)
    const rowLength = template.labelsPerRow

    const topNeighbor = globalData[0].data.find((cell) => cell.id === currentID - rowLength)
    const rightNeighbor = globalData[0].data.find((cell) => cell.id === currentID + 1)
    const bottomNeighbor = globalData[0].data.find((cell) => cell.id === currentID + rowLength)
    const leftNeighbor = globalData[0].data.find((cell) => cell.id === currentID - 1)

    const hasColoredTopNeighbor = !!topNeighbor?.color && topNeighbor?.color !== '#ffffff'
    const hasColoredRightNeighbor = !!rightNeighbor?.color && rightNeighbor?.color !== '#ffffff'
    const hasColoredBottomNeighbor = !!bottomNeighbor?.color && bottomNeighbor?.color !== '#ffffff'
    const hasColoredLeftNeighbor = !!leftNeighbor?.color && leftNeighbor?.color !== '#ffffff'

    const styles = {
      backgroundColor: '',
      borderTopLeftRadius: '',
      borderTopRightRadius: '',
      borderBottomLeftRadius: '',
      borderBottomRightRadius: '',
      borderColor: ''
    }

    if (template.rows === 2) {
      styles.borderTopLeftRadius = '0px'
      styles.borderTopRightRadius = '0px'
      styles.borderBottomLeftRadius = '0px'
      styles.borderBottomRightRadius = '0px'

      if (data.id < template.labelsPerRow + 1) {
        styles.borderTopLeftRadius = '8px'
        styles.borderTopRightRadius = '8px'
      } else {
        styles.borderBottomLeftRadius = '8px'
        styles.borderBottomRightRadius = '8px'
      }
    } else {
      styles.borderBottomLeftRadius = '8px'
      styles.borderBottomRightRadius = '8px'
      styles.borderTopLeftRadius = '8px'
      styles.borderTopRightRadius = '8px'
    }

    if (globalData[0].data.length > 0) {
      globalData[0].data.forEach((label, index) => {
        if (label.id === data.id) {
          styles.backgroundColor = label.color + ' !important'
        } else {
          styles.backgroundColor = '#F0EEF0'
        }
      })

      styles.backgroundColor = globalData[0].data[data.id - 1].color
    }

    if (globalData[0].groups.length > 0) {
      globalData[0].groups.forEach((group, index) => {
        if (group?.labels.indexOf(data.id) !== -1) {
          styles.backgroundColor = group.color
        }
      })
    }

    if (!groupData) {
      hasColoredTopNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID - rowLength))
        ? (styles.borderTopColor = '#000000')
        : (styles.borderTopStyle = 'hidden')
      hasColoredRightNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID + 1))
        ? (styles.borderRightColor = '#000000')
        : (styles.borderRightStyle = 'hidden')
      template.rows === 2 && currentID === template.labelsPerRow
        ? (styles.borderRightColor = '#FFFFFF')
        : (styles.borderRightColor = '#000000')
      hasColoredBottomNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID + rowLength))
        ? (styles.borderBottomColor = '#000000')
        : (styles.borderBottomStyle = 'hidden')
      hasColoredLeftNeighbor || globalData[0].groups.find((e) => e.labels.includes(currentID - 1))
        ? (styles.borderLeftColor = '#000000')
        : (styles.borderLeftStyle = 'hidden')
    }

    if (groupData !== undefined) {
      function updateStyles (
        topLeft,
        topRight,
        bottomLeft,
        bottomRight,
        topColor = '#000000',
        rightColor = '#000000',
        bottomColor = '#000000',
        leftColor = '#000000',
        topStyle = 'hidden',
        rightStyle = 'hidden',
        bottomStyle = 'hidden',
        leftStyle = 'hidden'
      ) {
        styles.borderTopLeftRadius = `${topLeft}px`
        styles.borderTopRightRadius = `${topRight}px`
        styles.borderBottomLeftRadius = `${bottomLeft}px`
        styles.borderBottomRightRadius = `${bottomRight}px`
        styles.borderTopColor = `${topColor}`
        styles.borderRightColor = `${rightColor}`
        styles.borderBottomColor = `${bottomColor}`
        styles.borderLeftColor = `${leftColor}`
        styles.borderTopStyle = `${topStyle}`
        styles.borderRightStyle = `${rightStyle}`
        styles.borderBottomStyle = `${bottomStyle}`
        styles.borderLeftStyle = `${leftStyle}`
      }

      const hasTopNeighbor = groupData.labels.includes(currentID - rowLength)
      const hasBottomNeighbor = groupData.labels.includes(currentID + rowLength)
      const hasLeftNeighbor = groupData.labels.includes(currentID - 1)
      const hasRightNeighbor = groupData.labels.includes(currentID + 1)
      if (template.rows === 2) {
        if (!hasTopNeighbor && !hasLeftNeighbor) {
          updateStyles(8, 0, 0, 0, '#000000', groupData.color, groupData.color, '#000000', 'solid', 'hidden', 'hidden', 'solid')
        }
        if (!hasTopNeighbor && !hasRightNeighbor) {
          updateStyles(0, 8, 0, 0, '#000000', '#000000', groupData.color, groupData.color, 'solid', 'solid', 'hidden', 'hidden')
        }
        if (!hasBottomNeighbor && !hasLeftNeighbor) {
          updateStyles(0, 0, 8, 0, groupData.color, groupData.color, '#000000', '#000000', 'hidden', 'hidden', 'solid', 'solid')
        }
        if (!hasBottomNeighbor && !hasRightNeighbor) {
          updateStyles(0, 0, 0, 8, groupData.color, '#000000', '#000000', groupData.color, 'hidden', 'solid', 'solid', 'hidden')
        }
        if (hasLeftNeighbor && hasRightNeighbor) {
          updateStyles(0, 0, 0, 0, '#000000', groupData.color, '#000000', groupData.color, 'solid', 'hidden', 'solid', 'hidden')
        }
        if (!hasTopNeighbor && hasLeftNeighbor && !hasRightNeighbor) {
          updateStyles(0, 8, 0, 0, '#000000', '#000000', groupData.color, groupData.color, 'solid', 'solid', 'hidden', 'hidden')
        }
        if (hasTopNeighbor && !hasLeftNeighbor && !hasRightNeighbor) {
          updateStyles(0, 0, 8, 8, groupData.color, '#000000', '#000000', '#000000', 'hidden', 'solid', 'solid', 'solid')
        }
        if (hasBottomNeighbor && !hasLeftNeighbor && !hasRightNeighbor) {
          updateStyles(8, 8, 0, 0, '#000000', '#000000', groupData.color, '#000000', 'solid', 'solid', 'hidden', 'solid')
        }
        if (!hasTopNeighbor && hasLeftNeighbor && hasRightNeighbor && hasBottomNeighbor) {
          updateStyles(0, 0, 0, 0, '#000000', groupData.color, groupData.color, groupData.color, 'solid', 'hidden', 'hidden', 'hidden')
        }
        if (hasTopNeighbor && hasLeftNeighbor && hasRightNeighbor && !hasBottomNeighbor) {
          updateStyles(0, 0, 0, 0, groupData.color, groupData.color, '#000000', groupData.color, 'hidden', 'hidden', 'solid', 'hidden')
        }
        if (!hasLeftNeighbor && !hasTopNeighbor && !hasBottomNeighbor && hasRightNeighbor) {
          updateStyles(8, 0, 0, 0, '#000000', groupData.color, '#000000', '#000000', 'solid', 'hidden', 'solid', 'solid')
        }
        if (!hasRightNeighbor && !hasTopNeighbor && !hasBottomNeighbor && hasLeftNeighbor) {
          updateStyles(0, 8, 0, 0, '#000000', '#000000', '#000000', groupData.color, 'solid', 'solid', 'solid', 'hidden')
        }
        if (!hasLeftNeighbor && !hasTopNeighbor && !hasBottomNeighbor && hasRightNeighbor && currentID > rowLength) {
          updateStyles(0, 0, 8, 0, '#000000', groupData.color, '#000000', '#000000', 'solid', 'hidden', 'solid', 'solid')
        }
        if (hasLeftNeighbor && !hasTopNeighbor && !hasBottomNeighbor && !hasRightNeighbor && currentID > rowLength) {
          updateStyles(0, 0, 0, 8, '#000000', '#000000', '#000000', groupData.color, 'solid', 'solid', 'solid', 'hidden')
        }
      } else {
        if (!hasRightNeighbor && hasLeftNeighbor) {
          updateStyles(0, 8, 0, 8, '#000000', '#000000', '#000000', groupData.color, 'solid', 'solid', 'solid', 'hidden')
        }
        if (hasRightNeighbor && hasLeftNeighbor) {
          updateStyles(0, 0, 0, 0, '#000000', groupData.color, '#000000', groupData.color, 'solid', 'hidden', 'solid', 'hidden')
        }
        if (hasRightNeighbor && !hasLeftNeighbor) {
          updateStyles(8, 0, 8, 0, '#000000', groupData.color, '#000000', '#000000', 'solid', 'hidden', 'solid', 'solid')
        }
      }
    }
    setLabelStyle(styles)
  }, [globalData])

  const checkIdInLabels = (data, cell) => {
    for (let i = 0; i < data.length; i++) {
      const labels = data[i].labels
      if (labels.includes(cell.id)) {
        const color = fontColorContrast(data[i].color)
        setFontColor(color)
        return setIsLabelInGroup(true)
      }
    }

    const color = fontColorContrast(cell.color)
    setFontColor(color)
    return setIsLabelInGroup(false)
  }

  useEffect(() => {
    if (globalData[0]?.groups?.length > 0) {
      const currentGroup = globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)

      const labels = currentGroup?.labels

      if (labels?.length > 0) {
        const newData = {
          groupRowCount: 0,
          groupRowItems: 0,
          secondRowStart: null
        }

        if (template.id === 'behringer_px3000') {
          if ((labels.includes(13) && labels.includes(15)) || (labels.includes(40) && labels.includes(42))) {
            newData.containFakeLabels = true
          }
        }
        if (template.id === isBlackLionPbrXlrTemplate) {
          if (labels.includes(1) && labels.includes(18)) {
            newData.containFakeLabels = true
          }
          if (labels.includes(1)) {
            newData.containedLeftFakeLabels = true
          }
          if (labels.includes(18)) {
            newData.containedRightFakeLabels = true
          }
        }
        if (template.id === isBlackLionPbrTrsTrs3 || template.id === isBlackLionPbrTrsBt) {
          if (labels.includes(1) || labels.includes(27)) {
            newData.containedLeftFakeLabels = true
          }
          if (labels.includes(26) || labels.includes(52)) {
            newData.containedRightFakeLabels = true
          }
          if (labels.includes(1) || labels.includes(27)) {
            newData.containFakeLabels = true
          }
          if (labels.includes(26) || labels.includes(52)) {
            newData.containFakeLabels = true
          }
        }

        if (labels.every((num) => num <= template.labelsPerRow)) {
          newData.groupRowCount = 1
        }
        if (labels.every((num) => num > template.labelsPerRow)) {
          newData.groupRowCount = 1
        }
        if (labels.some((num) => num <= template.labelsPerRow) && labels.some((num) => num > template.labelsPerRow)) {
          newData.groupRowCount = 2
        }

        newData.groupRowItems = labels.length / newData.groupRowCount

        newData.secondRowStart = labels.some((num) => num > template.labelsPerRow) ? labels[newData.groupRowItems] : null

        if (currentGroup !== undefined) {
          setFirstID(labels[0])
        }

        setGroupData(newData)
      }
    }

    checkIdInLabels(globalData[0].groups, data)
  }, [globalData])

  useEffect(() => {
    if (template.firstLarge === true) {
      const cornerLabels = [1, template.labelsPerRow, template.labelsPerRow + 1, template.labelsPerRow * template.rows]

      if (cornerLabels.includes(data.id)) {
        setIsBigLabel(true)
      }
    }

    let fakeLabelsArray = []

    if (template.fakeLabels === true) {
      const fakeLabelID = (template.labelsPerRow / 2).toFixed(0)

      if (template.rows > 1) {
        fakeLabelsArray = [Number(fakeLabelID), Number(fakeLabelID) + Number(template.labelsPerRow)]
      }
    }

    if (fakeLabelsArray.includes(data.id)) {
      setIsFakeLabel(true)
    }
  }, [])

  return (
    <>
      {template.double === true
        ? (
        <div
          style={labelStyle}
          className={`labelItem EditHide doubleLabel
          ${!isLabelInGroup && data.color !== 'white' && data.color !== '#ffffff' && 'labelItemBorder'}
          ${isLabelInGroup === true ? 'groupLabelSelected' : ''}
          ${middleLine === true && data.id < template.labelsPerRow + 1 ? 'middleLine' : ''} `}
        >
          <div
            className={`${isSecondRowItem && template.id !== isArtXlrTemplate ? getTopLabelSecondRowStyle() : getTopLabelFirstRowStyle()}`}
          >
            <p
              style={{
                color: fontColor,
                marginLeft: data.id === 1 ? '30px' : 'auto',
                marginRight: data.id === template.labelsPerRow ? '30px' : 'auto'
              }}
              className={`topLabel ${fontClass} ${data.topLabel ? 'changedLabel' : 'Edit'}`}
            >
              {!!isLabelInGroup && getBigLabelPosition() === 1 ? '' : data.topLabel}
            </p>
          </div>
          {(getBigLabelPosition() === 1 && !!isLabelInGroup && firstID === data.id) ||
          (!!isLabelInGroup && groupData.secondRowStart === data.id)
            ? (
            <p
              className={`labelHorCenter ${fontClass}`}
              style={
                ({ color: fontColor },
                isBigLabel === true
                  ? { width: groupData.groupRowItems * 175 - 135 + 215 + 'px' }
                  : { width: groupData.groupRowItems * 175 + 'px' })
              }
            >
              {globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)?.topText}
            </p>
              )
            : null}

          <img
            src={double}
            style={index === 0 ? { left: '45px' } : {}}
            className={`doubleLabelImage ${data.id % 2 ? '' : 'transparent'}`}
            alt="Double"
          />
          {template.firstLarge === true
            ? (
            <p
              style={{
                color: fontColor,
                marginLeft: data.id === 1 ? '30px' : 'auto',
                marginRight: data.id === template.labelsPerRow ? '30px' : 'auto'
              }}
              className={`middleLabel ${fontClass} ${data.middleLabel ? 'changedLabel' : 'Edit'}`}
            >
              {data.middleLabel}
            </p>
              )
            : null}
          {(getBigLabelPosition() === 2 && isLabelInGroup === true && firstID === data.id) ||
          firstID === data.id ||
          (isLabelInGroup === true && groupData.secondRowStart === data.id)
            ? (
            <p
              className={`labelHorCenterBottom ${getBottomBigLabelStyle()} ${fontClass}`}
              style={{
                color: fontColor,
                width: isBigLabel ? groupData.groupRowItems * 175 - 135 + 215 + 'px' : groupData.groupRowItems * 175 + 'px'
              }}
            >
              {globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)?.bottomText}
            </p>
              )
            : null}
          <div
            className={`${
              isSecondRowItem && template.id !== isArtXlrTemplate ? getBottomLabelSecondRowStyle() : getBottomLabelFirstRowStyle()
            }`}
          >
            <p
              style={{
                color: fontColor,
                marginLeft: data.id === 1 ? '30px' : 'auto',
                marginRight: data.id === template.labelsPerRow ? '30px' : 'auto'
              }}
              className={`bottomLabel doubleTemplateBottomLabel ${fontClass} ${data.bottomLabel ? 'changedLabel' : 'Edit'}`}
            >
              {!!isLabelInGroup && getBigLabelPosition() === 2 ? '' : data.bottomLabel}
            </p>
          </div>
          <LabelNumbering id={data.id} globalData={globalData} />
        </div>
          )
        : (
        <div
          style={labelStyle}
          className={`labelItem EditHide mergeLabel ${template.id === isBlackLionPbrTrsTrs3 && 'blackLionPbrTrsTrs3CellWr'} ${
            template.id === isBlackLionPbrXlrTemplate && 'blackLionPbrXlrCellWr'
          } ${template.id === isBlackLionPbrTrsBt && 'blackLionPbrTrsBtCellWr'} ${
            ((data.id === globalData[0].data.length && template.id === isBlackLionPbrXlrTemplate) ||
              (data.id === 1 && template.id === isBlackLionPbrXlrTemplate)) &&
            'blackLionPbrXlrSmallCellWr'
          } ${
            ((data.id === globalData[0].data.length && template.id === isBlackLionPbrTrsTrs3) ||
              (data.id === 1 && template.id === isBlackLionPbrTrsTrs3) ||
              (data.id === centerOfDataList && template.id === isBlackLionPbrTrsTrs3) ||
              (data.id === centerOfDataList - 1 && template.id === isBlackLionPbrTrsTrs3)) &&
            'blackLionPbrTrsTrs3SmallCellWr'
          } ${
            ((data.id === globalData[0].data.length && template.id === isBlackLionPbrTrsBt) ||
              (data.id === 1 && template.id === isBlackLionPbrTrsBt) ||
              (data.id === centerOfDataList && template.id === isBlackLionPbrTrsBt) ||
              (data.id === centerOfDataList - 1 && template.id === isBlackLionPbrTrsBt)) &&
            'blackLionPbrTrsTrs3SmallCellWr'
          } ${!isLabelInGroup && data.color !== 'white' && data.color !== '#ffffff' && 'labelItemBorder'}
          ${template.id === isArtTemplate && (data.id === 1 || data.id === centerOfDataList) && 'artWiderItemLeft'} ${
            template.id === isArtTemplate &&
            (data.id === centerOfDataList - 1 || data.id === globalData[0].data.length) &&
            'artWiderItemRight'
          } ${isFakeLabel === true ? 'fakeLabel' : ''} ${isBigLabel && 'onlyOneLabel'} ${
            isLabelInGroup === true ? 'groupLabelSelected' : ''
          }`}
        >
          <div className={`${!!middleLine && data.id < template.labelsPerRow + 1 ? 'middleLine' : ''} pointerNone`} />
          <div className={`${template.id === isBlackLionPbrTrsBt && data.id === centerOfDataList - 2 && 'blackLionPbrTrsBtRectangle'} pointerNone`} />
          <div
            className={`${
              template.id === isBehringerTemplate && (data.id === 1 || data.id === centerOfDataList) && 'largeTemplateLabelWr'
            }`}
          >
            {(getBigLabelPosition() === 1 && !!isLabelInGroup && firstID === data.id) ||
            (getBigLabelPosition() === 1 && !!isLabelInGroup && groupData.secondRowStart === data.id)
              ? (
              <p className={`labelHorCenter ${getTopBigLabelStyle()} ${fontClass}`} style={{ width: bigLabelWidth, color: fontColor }}>
                {globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)?.topText}
              </p>
                )
              : null}
            <div
              className={`${isBigLabel && 'hideLabel'} ${
                template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && 'hideLabel'
              } ${isSecondRowItem ? getTopLabelSecondRowStyle() : getTopLabelFirstRowStyle()}`}
            >
              <p
                style={{ color: fontColor }}
                className={`topLabel ${
                  template.id === isBehringerTemplate &&
                  (data.id === centerOfDataList - 1 || data.id === globalData[0].data.length) &&
                  'mirroredLargeItemLabel'
                } ${fontClass} ${data.topLabel ? 'changedLabel' : 'Edit'}`}
              >
                {isLabelInGroup && getBigLabelPosition() === 1 ? '' : data.topLabel}
              </p>
            </div>
          </div>
          <div
            className={`labelCircle ${
              template.id === isBlackLionPbrTrsBt && data.id === centerOfDataList - 2 && 'blackLionPbrTrsBtRectangleFirstRow'
            } ${
              template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && 'blackLionPbrTrsBtRectangleSecondRow'
            } ${template.id === isBlackLionPbrXlrTemplate && 'blackLionPbrXlrCircle'} ${
              template.id === isBehringerTemplate &&
              (data.id === centerOfDataList - 1 || data.id === globalData[0].data.length) &&
              'mirroredLargeItemCircle'
            } ${getCircleSize()}`}
          >
            <img className="mergeSelectMark" src={select_icon} alt="Select" />
          </div>
          <div
            className={`${
              template.id === isBehringerTemplate && (data.id === 1 || data.id === centerOfDataList) && 'largeTemplateLabelWr'
            }`}
          >
            {(getBigLabelPosition() === 2 && !!isLabelInGroup && firstID === data.id) ||
            (getBigLabelPosition() === 2 && !!isLabelInGroup && groupData.secondRowStart === data.id)
              ? (
              <p
                className={`labelHorCenterBottom ${getBottomBigLabelStyle()} ${fontClass}`}
                style={{ width: bigLabelWidth, color: fontColor }}
              >
                {globalData[0].groups.find((e) => e.labels.indexOf(data?.id) !== -1)?.bottomText}
              </p>
                )
              : null}
            <div
              className={`bottomLabelWr ${
                template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && 'blackLionPbrTrsBtBottomLabelWrMargin'
              } ${template.id === isBlackLionPbrTrsBt && data.id === centerOfDataList - 2 && 'hideLabel'} ${
                template.id === isBlackLionPbrTrsBt && data.id === globalData[0].data.length - 1 && 'blackLionPbrTrsBtPreLastInput'
              } ${isBigLabel && 'hideLabel'} ${isSecondRowItem ? getBottomLabelSecondRowStyle() : getBottomLabelFirstRowStyle()}`}
            >
              <p
                style={{ color: fontColor }}
                className={`bottomLabel ${
                  template.id === isBehringerTemplate &&
                  (data.id === centerOfDataList - 1 || data.id === globalData[0].data.length) &&
                  'mirroredLargeItemLabel'
                } ${fontClass} ${data.bottomLabel ? 'changedLabel' : 'Edit'} ${
                  template.id === isBehringerTemplate &&
                  (data.id === centerOfDataList || data.id === globalData[0].data.length) &&
                  'behringerBottomCenterLabel'
                }`}
              >
                {!!isLabelInGroup && getBigLabelPosition() === 2 ? '' : data.bottomLabel}
              </p>
            </div>
          </div>
          {isBigLabel && (
            <p
              id="oneLabelInput"
              style={{ color: fontColor }}
              className={`onlyOneLabelInput ${template.id === isBlackLionPbrXlrTemplate && 'blackLionPbrXlrOnlyOneLabel'} ${fontClass} ${
                data.middleLabel ? 'changedLabel' : 'Edit'
              }`}
            >
              {data.middleLabel}
            </p>
          )}
          {(template.id === isBehringerTemplate ||
            template.id === isBlackLionPbrTrsTrs3 ||
            template.id === isBlackLionPbrTrsBt ||
            template.id === isBlackLionPbrXlrTemplate) &&
          (data.id === 1 ||
            data.id === template.labelsPerRow ||
            data.id === template.labelsPerRow + 1 ||
            data.id === template.labelsPerRow * template.rows)
            ? null
            : (
            <LabelNumbering id={labelNumber} globalData={globalData} />
              )}
        </div>
          )}
    </>
  )
}

export default LabelFinal
